import React from "react";
import powerBi from "../../assets/images/power-bi.svg";
import powerVirtual from "../../assets/images/power-virtual.svg";
import Power from "../../assets/images/Power.svg";
import Maskgroup from "../../assets/images/Maskgroup.svg";
import powerPointIcon from "../../assets/images/powerPointIcon.svg";
import Frame413 from "../../assets/images/Frame413.svg";
import PowerPages from "../../assets/images/PowerPages.svg";
import icons8Microsoft from "../../assets/images/icons8Microsoft.svg";

const CircularImageSecion = () => {
  return <div>{ImageContainer()}</div>;
};

const ImageContainer = () => {
  return (
    <svg
      className="w-70 h-70 mx-auto"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: "rgba(168, 234, 255, 0.55)", stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "rgba(105, 167, 224, 0.5225)", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <ellipse cx="50" cy="50" rx="40" ry="40" fill="url(#grad)" />
      <ellipse cx="50" cy="50" rx="15" ry="15" fill="#ffffff" />
      <image href={powerBi} x="42" y="42" width="15" height="15" />
      <text x="50" y="60" textAnchor="middle" fontSize="2">
        Center
      </text>
      <image href={icons8Microsoft} x="43" y="12" width="15" height="15" />
      <text x="50" y="28" textAnchor="middle" fontSize="2">
        Top
      </text>
      <image href={Maskgroup} x="43" y="70" width="15" height="15" />
      <text x="50" y="88" textAnchor="middle" fontSize="2">
        Bottom
      </text>
      <image href={powerVirtual} x="15" y="42" width="15" height="15" />
      <text x="20" y="60" textAnchor="middle" fontSize="2">
        Left
      </text>
      <image href={Frame413} x="70" y="42" width="15" height="15" />
      <text x="78" y="56" textAnchor="middle" fontSize="2">
        Right
      </text>
      <image href={powerBi} x="22" y="20" width="15" height="15" />
      <text x="30" y="38" textAnchor="middle" fontSize="2">
        Top Left
      </text>
      <image href={PowerPages} x="62" y="20" width="15" height="15" />
      <text x="70" y="38" textAnchor="middle" fontSize="2">
        Top Right
      </text>
      <image href={Power} x="22" y="62" width="15" height="15" />
      <text x="30" y="78" textAnchor="middle" fontSize="2">
        Bottom Left
      </text>
      <image href={powerPointIcon} x="62" y="60" width="15" height="15" />
      <text x="70" y="78" textAnchor="middle" fontSize="2">
        Bottom Right
      </text>
    </svg>
  );
};

export default CircularImageSecion;
