import React, { Fragment } from "react";
import DynamicRibbon from "../components/DynamicRibbon/DynamicRibbon";
import vCISO from "../assets/images/vcisco/vciso-powerbi-banner.png";
import advantage from "../assets/images/vcisco/advantage.png";
import empowerOrganization from "../assets/images/vcisco/empower-organization.png";
import empowerRibbon from "../assets/images/vcisco/empower-ribbon.png";
import excellence from "../assets/images/vcisco/excellence.png";
import successRibbon from "../assets/images/vcisco/success-ribbon.png";
import unlockDtrategicRibbon from "../assets/images/vcisco/unlock-strategic-ribbon.png";
import expertsCallRibbon from "../assets/images/experts-call-ribbon.png";

import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import ImageContainer from "../components/ImageContainer/ImageContainer";
import GetInTouch from "../components/GetInTouch/GetInTouch";

const VCisco = () => {
  const vCISOExpertise = [
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
    {
      image: "azure",
      clx: "w-[147px] h-12",
    },
  ];

  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img src={vCISO} alt="vCISO" className="h-[140px] md:h-auto" />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          vCISO
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-8 max-w-6xl mx-auto text-center">
        <div className="text-4xl madeTommyDark">
          <span className="gradientText">vCISO</span>: Empowering Your
          Cybersecurity Leadership
        </div>
        <p className="text-[#808080] text-lg pt-7">
          A vCISO, or virtual Chief Information Security Officer, is a trusted
          cybersecurity expert who provides strategic guidance and leadership to
          organizations without the need for a full-time, in-house CISO.
          Leveraging industry expertise, a vCISO helps businesses develop and
          execute effective cybersecurity strategies, manage risks, and
          safeguard valuable assets. With a vCISO, you gain access to high-level
          cybersecurity leadership tailored to your specific needs, ensuring the
          protection and resilience of your organization in today's evolving
          threat landscape.
        </p>
      </div>
      <div className="my-12 md:my-14">
        <DynamicRibbon
          bgImg={empowerRibbon}
          description={
            "Empower Your Organisation with Strategic Cybersecurity Leadership"
          }
          clx={"!h-[148px]"}
        />
      </div>
      <div className="text-[#808080] text-lg my-5 max-w-6xl mx-auto text-center">
        In today's rapidly evolving threat landscape, organizations face
        increasing challenges in managing cyber risks, protecting sensitive
        data, and maintaining regulatory compliance. Blue Orca Solutions
        understands the importance of robust cybersecurity and offers a virtual
        Chief Information Security Officer (vCISO) service to empower your
        organization with expert guidance and strategic cybersecurity
        leadership.
      </div>
      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={unlockDtrategicRibbon}
          description={"Unlock Strategic Cybersecurity Expertise"}
          clx={"!h-[148px]"}
        />
      </div>
      <div className="text-[#808080] text-lg my-5 max-w-6xl mx-auto text-center">
        Our vCISO services bring together a team of seasoned cybersecurity
        advisors and subject matter experts from Blue Orca Solutions. With their
        wealth of experience, they will assess your unique security needs,
        develop a tailored security roadmap, and guide you towards an optimal
        cybersecurity posture that aligns with your business objectives.
      </div>
      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={successRibbon}
          description={"Comprehensive vCISO Program: Key Steps for Success"}
          clx={"!h-[148px]"}
        />
      </div>

      <section className="flex gap-12 flex-col">
        <div
          className="max-w-6xl mx-auto flex gap-4 p-4 pl-8 rounded-2xl"
          style={{
            boxShadow: "11.172px 13.566px 17.556px 0px rgba(0, 133, 255, 0.26)",
          }}
        >
          <div>
            <div className="madeTommyDark text-2xl text-center">
              Simplifying <span className="gradientText">Cybersecurity</span>{" "}
              Excellence
            </div>
            <div className="text-[#808080] pt-12">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <span className="madeTommyDark">
                    Assessment and Planning:
                  </span>{" "}
                  Thorough evaluation of your security landscape, identifying
                  vulnerabilities and mapping dependencies.
                </li>
                <li>
                  <span className="madeTommyDark">Strategic Guidance:</span>{" "}
                  Trusted advisory to align cybersecurity with business goals
                  and mitigate risks.
                </li>
                <li>
                  <span className="madeTommyDark">
                    Risk Management and Compliance:
                  </span>{" "}
                  Developing robust policies, implementing best practices,
                  ensuring regulatory adherence.
                </li>
                <li>
                  <span className="madeTommyDark">
                    Incident Response Planning:
                  </span>{" "}
                  Preparedness for cybersecurity incidents with effective
                  response plans.
                </li>
                <li>
                  <span className="madeTommyDark">Continuous Support:</span>{" "}
                  Ongoing assistance, updates, and reviews to enhance your
                  security program.
                </li>
              </ul>
            </div>
          </div>
          <img src={excellence} />
        </div>

        <div
          className="max-w-6xl mx-auto flex gap-8 p-4 rounded-2xl"
          style={{
            boxShadow: "11.172px 13.566px 17.556px 0px rgba(0, 133, 255, 0.26)",
          }}
        >
          <img src={advantage} className="rounded-2xl" />
          <div>
            <div className="madeTommyDark text-2xl text-center">
              Blue Orca <span className="gradientText">Advantage</span>
            </div>
            <div className="text-[#808080] pt-12">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <span className="madeTommyDark">Tailored Approach:</span>{" "}
                  Customized strategies to meet your unique cybersecurity needs.
                </li>
                <li>
                  <span className="madeTommyDark">
                    Cost-Effective Solution:
                  </span>{" "}
                  Access top-tier expertise without the overhead costs of a
                  full-time CISO.
                </li>
                <li>
                  <span className="madeTommyDark">
                    Proactive Risk Mitigation:
                  </span>{" "}
                  Stay ahead of threats with preventive measures and a robust
                  security program.
                </li>
                <li>
                  <span className="madeTommyDark">
                    Industry Compliance Made Easy:
                  </span>{" "}
                  Ensure adherence to regulations and data protection standards
                  effortlessly.
                </li>
                <li>
                  <span className="madeTommyDark">Trusted Partnership:</span>{" "}
                  Count on our dedicated team as your reliable cybersecurity
                  advisors.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={empowerOrganization}
          description={
            "Empower Your Organization's Cybersecurity Journey with Blue Orca Solutions"
          }
          clx={"!h-[148px]"}
        />
      </div>

      <div className="max-w-6xl mx-auto flex gap-12 pt-8 flex-wrap items-center justify-center">
        {vCISOExpertise.map((technology) => (
          <ImageContainer image={technology.image} clx={technology.clx} />
        ))}
      </div>

      <div className="mt-12 md:mt-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>

      <div className="mt-12 md:mt-24">
        <GetInTouch />
      </div>
    </Fragment>
  );
};

export default VCisco;
