import React, { Fragment } from "react";
import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import leftArrow from "../../assets/images/arrow-left.svg";
import rightArrow from "../../assets/images/arrow-right-carousel.svg";

const TestimonialCarousel = (props) => {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(6);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <Fragment>
      <div
        className="hidden md:flex left-24 relative top-52 w-28 h-28 rounded-full items-center justify-center cursor-pointer"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
        onClick={(e) => setGoToSlide((goToSlide) => goToSlide - 1)}
      >
        <img src={leftArrow} />
      </div>

      <div
        style={{
          width: props.width,
          height: props.height,
          margin: props.margin,
        }}
      >
        <Carousel
          slides={cards}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation={showArrows}
          animationConfig={config.gentle}
        />
      </div>

      <div
        className="hidden md:flex relative top-52 right-24 w-28 h-28 rounded-full items-center justify-center cursor-pointer"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
        onClick={(e) => setGoToSlide((goToSlide) => goToSlide + 1)}
      >
        <img src={rightArrow} />
      </div>
    </Fragment>
  );
};

export default TestimonialCarousel;
