import React from "react";
import studyOne from "../../assets/images/case-studies/study-one.png";
import studyTwo from "../../assets/images/case-studies/study-two.png";
import studyThree from "../../assets/images/case-studies/study-three.png";
import studyFour from "../../assets/images/case-studies/study-four.png";
import studyFive from "../../assets/images/case-studies/study-five.png";
import { Link } from "react-router-dom";
const CaseStudySection = () => {
  const caseStudiesArr = [
    {
      img: studyOne,
      title: "Streamlining IT Operations for a Leading Logistics...",
      link: "/it-operations",
      clx: "h-[96px] md:max-w-[682px] md:h-[178px]",
    },
    {
      img: studyTwo,
      title: "Enhancing IT Support for a ...",
      clx: "h-[96px] md:max-w-[442px] md:h-[178px]",
    },
    {
      img: studyThree,
      title: "Red Teaming Exercise for a Leading BPO Company in Canada",
    },
    {
      img: studyFour,
      title: "Penetration Testing and ...",
      clx: "w-[442px] h-[178px]",
    },
    {
      img: studyFive,
      title: "BOT Analysis for an E-commerce Website",
      clx: "w-[682px] h-[178px]",
    },
  ];
  return (
    <div>
      <div className="block md:hidden">
        <div className="flex gap-4 px-8">
          <div className="w-1/2">
            <Link to={"/case-studies"}>
              <div className="mt-2"> {"<<"} Previous</div>
            </Link>
            <ImageContainer
              image={caseStudiesArr[0].img}
              title={trim_words(caseStudiesArr[0].title, 3) + "..."}
              url={caseStudiesArr[0].link}
              clx={caseStudiesArr[0].clx}
            />
          </div>

          <div className="w-1/2">
            <div className="flex justify-end min-w-full">
              <Link to={"/case-studies"}>
                <div className="mt-2">Next {">>"}</div>
              </Link>
            </div>
            <ImageContainer
              image={caseStudiesArr[1].img}
              title={trim_words(caseStudiesArr[1].title, 3) + "..."}
              url={caseStudiesArr[1].link}
              clx={caseStudiesArr[1].clx}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="text-4xl madeTommyDark text-center mt-16">
          Case Studies
        </div>

        <div className="mt-4 md:mt-16 flex flex-wrap gap-[26px] max-w-6xl mx-auto">
          {caseStudiesArr.map((study) => (
            <ImageContainer
              image={study.img}
              title={study.title}
              url={study.link}
              clx={study.clx}
            />
          ))}
          <div className="flex justify-end min-w-full">
            <Link to={"/case-studies"}>
              <div className="mt-2">See all {">>"}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageContainer = ({ image, title, url, clx }) => {
  return (
    <Link to={url}>
      <div className="inline-block overflow-hidden relative rounded-xl cursor-pointer">
        <div className="mainGroupImage">
          <img src={image} alt={`${image}`} className={clx} />
        </div>
        <div className="absolute text-white bottom-2 left-2 md:bottom-6 md:left-5 font-bold madeTommyDark text-xs md:text-lg">
          {title}
        </div>
      </div>
    </Link>
  );
};
function trim_words(theString, numWords) {
  let expString = theString.split(/\s+/, numWords);
  let theNewString = expString.join(" ");
  return theNewString;
}
export default CaseStudySection;
