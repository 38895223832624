import React from "react";
import TestimonialCarousel from "../TestimonialCarousel/TestimonialCarousel";
import Card from "../Card/Card";

const Testimonials = () => {
  let cards = [
    {
      key: 1,
      content: (
        <Card
          imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/acf_pro.png"
          title={"James Anderson"}
          designation={"CEO, Anderson Tech"}
          description="Our company was in dire need of a digital transformation, and Blue Orca Solutions was the perfect partner for this journey. They expertly handled our migration to Microsoft 365, ensuring a smooth transition with minimal disruption to our operations. Their team also facilitated our Azure cloud migration, providing us with a more scalable and cost-effective solution. But what truly sets them apart is their proficiency in data visualization. They created Power BI dashboards that have revolutionized the way we analyze and interpret our data, leading to more informed business decisions. Blue Orca Solutions has truly exceeded our expectations in every way."
        />
      ),
    },
    {
      key: 2,
      content: (
        <Card
          imagen="https://updates.theme-fusion.com/wp-content/uploads/2016/08/slider_revolution-1.png"
          title={"Robert Williams"}
          designation={"CTO, Williams Enterprises"}
          description="Our company was looking for a reliable partner to manage our Nutanix and Citrix-based environment, and Blue Orca Solutions proved to be the perfect fit. Their team demonstrated a deep understanding of these platforms, ensuring optimal performance and efficiency. They didn't just stop at management; they also implemented robust cybersecurity hardening measures to protect our systems from potential threats. Their proactive approach to security has given us peace of mind, knowing our systems are secure and our data is protected. Blue Orca Solutions' expertise and commitment to client satisfaction are truly commendable."
        />
      ),
    },
    {
      key: 3,
      content: (
        <Card
          imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/layer_slider_plugin_thumb.png"
          title={"Emily Davis"}
          designation={"IT Manager, Davis & Co."}
          description="As a company dealing with sensitive data, ensuring the highest level of cybersecurity was paramount for us. Blue Orca Solutions provided us with an in-depth cybersecurity audit, vulnerability assessment, and penetration testing that was nothing short of comprehensive. Their team then worked diligently to remediate identified issues, strengthening our overall cybersecurity posture. They didn't just stop there; they provided us with an end-to-end cybersecurity update, ensuring we are equipped to handle future threats. Their expertise and commitment to client security are truly commendable. If you're looking for a robust cybersecurity partner, I highly recommend Blue Orca Solutions."
        />
      ),
    },
    {
      key: 4,
      content: (
        <Card
          imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
          title={"Sarah Thompson"}
          designation={"Owner, Thompson's Bakery"}
          description={
            "As a small business owner, managing our IT infrastructure was becoming increasingly challenging. That's when we turned to Blue Orca Solutions. Their Managed Security Services Provider (MSSP) offering has been a lifesaver. They've taken over the complete management of our IT services, providing us with robust security and seamless network operations. Their IT Helpdesk Support is top-notch, always ready to resolve any issues promptly and efficiently. With their end-to-end IT services, I can now focus on my core business without worrying about IT-related concerns. Blue Orca Solutions is truly a one-stop-shop for all IT needs."
          }
        />
      ),
    },
    // {
    //   key: 5,
    //   content: (
    //     <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2019/01/pwa_880_660.jpg" />
    //   ),
    // },
  ];
  return (
    <div className="pt-12 md:py-24">
      <div className=" text-center">
        <span className="font-black text-xl md:text-4xl madeTommyDark">
          Testimonials
        </span>
        <div className="md:mt-24 flex">
          <TestimonialCarousel
            cards={cards}
            height="500px"
            width="50%"
            margin="0 auto"
            offset={12}
            showArrows={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
