const ImageContainer = ({ image, clx }) => {
  return (
    <div
      className="w-[215px] h-[81px] bg-white rounded-3xl border-2 flex items-center justify-center"
      style={{
        filter: "drop-shadow(9px 10px 19.736px rgba(141, 141, 141, 0.30))",
      }}
    >
      <img src={image} className={clx} />
    </div>
  );
};

export default ImageContainer;
