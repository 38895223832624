import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import Cloud from "./pages/Services/Cloud";
import CyberSecurity from "./pages/Services/CyberSecurity";
import ApplicationModernization from "./pages/Services/ApplicationModernization";
import DataScience from "./pages/Services/DataScience";
import ScrollToTop from "./utils/ScrollToTop";
import ContactUs from "./pages/ContactUs";
import CaseStudies from "./pages/Services/CaseStudies";
import ITOperations from "./pages/CaseStudies/ITOperations";
import IT from "./pages/Services/IT";
import ITDetail from "./pages/DetailServices/IT";
import CloudDetail from "./pages/DetailServices/Cloud";
import CyberSecurityDetail from "./pages/DetailServices/CyberSecurity";
import ApplicationModernizationDetail from "./pages/DetailServices/ApplicationModernization";
import DataScienceDetail from "./pages/DetailServices/DataScience";
import VCisco from "./pages/VCisco";
import PowerBI from "./pages/PowerBI";

function App() {
  return (
    <div className="flex flex-col antialiased">
      <Header />
      {routes}
      <Footer />
    </div>
  );
}

const routes = (
  <ScrollToTop>
    <Routes>
      <Route path={"/"} element={<Home />}></Route>
      <Route path={"/services/managed-i-t-services"} element={<IT />}></Route>
      <Route
        path={"/services/managed-i-t-services/it-helpdesk-support"}
        element={<ITDetail />}
      ></Route>
      <Route path={"/services/cloud-service"} element={<Cloud />}></Route>
      <Route
        path={"/services/cloud-service/detailed-cloud-services"}
        element={<CloudDetail />}
      ></Route>
      <Route path={"/services/devops"} element={<Cloud />}></Route>
      <Route
        path={"/services/cyber-security"}
        element={<CyberSecurity />}
      ></Route>
      <Route
        path={"/services/cyber-security/detailed-cyber-security"}
        element={<CyberSecurityDetail />}
      ></Route>
      <Route
        path={"/services/application-modernization"}
        element={<ApplicationModernization />}
      ></Route>
      <Route
        path={
          "/services/application-modernization/detailed-application-modernization"
        }
        element={<ApplicationModernizationDetail />}
      ></Route>
      <Route path={"/services/data-science"} element={<DataScience />}></Route>
      <Route
        path={"/services/data-science/detailed-data-science"}
        element={<DataScienceDetail />}
      ></Route>
      <Route path={"/contact-us"} element={<ContactUs />}></Route>
      <Route path={"/case-studies"} element={<CaseStudies />}></Route>
      <Route
        path={"/case-studies/it-operations"}
        element={<ITOperations />}
      ></Route>

      <Route path={"/vciso"} element={<VCisco />}></Route>

      <Route path={"/power-bi"} element={<PowerBI />}></Route>
    </Routes>
  </ScrollToTop>
);
export default App;
