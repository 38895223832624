import React from "react";
import Service from "../Service/Service";
import { services } from "../../utils/services";

const Services = () => {
  return (
    <div id="services" className="py-6 md:py-12 md:px-0  max-w-6xl mx-auto">
      <div className="font-black text-xl md:text-3xl text-center">
        <span className="madeTommyDark"> Our Services</span>
        <div className="mt-12 lg:mt-24 flex flex-wrap justify-center items-center gap-x-2 md:gap-x-8 gap-y-12 md:gap-y-24">
          {services.map((service) => (
            <Service
              title={service.title}
              description={service.description}
              logo={service.logo}
              url={service.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
