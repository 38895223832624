import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Technologies from "../../components/Technologies/Technologies";
import DetailChildService from "../../components/DetailChildService/DetailChildService";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import cloudService from "../../assets/images/services/cloud/cloud-services.png";
import cloudMigration from "../../assets/images/services/cloud/cloud-migration.svg";
import azure from "../../assets/images/azure.png";
import aws from "../../assets/images/aws.svg";
import googleCloud from "../../assets/images/services/cloud/google-cloud.png";
import digitalOcean from "../../assets/images/services/cloud/digital-ocean.png";
import ibmCloud from "../../assets/images/services/cloud/ibm-cloud.png";
import oracleCloud from "../../assets/images/services/cloud/oracle-cloud.png";
import redHat from "../../assets/images/red-hat.png";
import salesforce from "../../assets/images/salesforce.png";
import coLocation from "../../assets/images/services/cloud/co-location.png";
import publicCloud from "../../assets/images/services/cloud/public-cloud.png";
import { cloudServices } from "../../utils/services/cloud";
import ComplexRibbon from "../../components/ComplexRibbon/ComplexRibbon";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
const Cloud = () => {
  const cloudExpertise = [
    {
      image: azure,
      clx: "w-[147px] h-12",
    },
    {
      image: aws,
      clx: "w-[147px] h-12",
    },
    {
      image: googleCloud,
      clx: "w-[126px] h-[70px]",
    },
    {
      image: digitalOcean,
      clx: "w-[76px] h-[76px]",
    },
    {
      image: ibmCloud,
      clx: "w-[113px] h-[70px]",
    },
    {
      image: oracleCloud,
      clx: "w-[119px] h-[68px]",
    },
    {
      image: redHat,
      clx: "w-[154px] h-[46px]",
    },
    {
      image: salesforce,
      clx: "w-[103px] h-[72px]",
    },
  ];
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={cloudService}
          alt="cloud service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Cloud Services
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-8 xl:pt-16 pb-8 flex justify-center flex-wrap gap-10 max-w-6xl mx-auto">
        {cloudServices.map((itService, key) => (
          <DetailChildService
            id={itService.id}
            logo={itService.mobileLogo}
            title={itService.title}
            description={itService.description}
            uuid={itService.uuid}
          />
        ))}
      </div>
      <div
        className="h-[743px] my-10 max-w-6xl mx-auto rounded-3xl"
        style={{
          boxShadow: "11.172px 13.566px 17.556px 0px rgba(0, 133, 255, 0.26)",
        }}
      >
        <div className="flex justify-center">
          <div
            className="madeTommyDark text-3xl py-5 w-[500px] text-center rounded-3xl h-[84px] mt-6"
            style={{
              boxShadow:
                "11.172px 13.566px 17.556px 0px rgba(0, 133, 255, 0.26)",
            }}
          >
            Cloud Migration
          </div>
        </div>
        <div className="pt-8 flex justify-center relative">
          <img src={cloudMigration}></img>

          <CustomizedButton
            title={"Rehost (Lift and Shift)"}
            clx={"top-10 left-[100px]"}
          />
          <CustomizedButton title={"Hybrid"} clx={"top-10 right-[100px]"} />

          <CustomizedButton
            title={"Refactor (Replatform)"}
            clx={"top-44 left-[20px]"}
          />
          <CustomizedButton title={"Retire"} clx={"top-44 right-[20px]"} />

          <CustomizedButton
            title={"Rearchitect (Rebuild)"}
            clx={"top-80 left-[100px]"}
          />
          <CustomizedButton
            title={"Multi-cloud"}
            clx={"top-80 right-[100px]"}
          />

          <CustomizedButton
            title={"Replatform (Repurchase)"}
            clx={"top-[460px] left-[180px]"}
          />

          <CustomizedButton
            title={"Recover"}
            clx={"top-[460px] right-[180px]"}
          />

          <CustomizedButton title={"Retain (Revisit)"} clx={"top-[540px]"} />
        </div>
      </div>

      <div className="madeTommyDark text-3xl text-center">
        Public Cloud Expertise
      </div>

      <div className="max-w-6xl mx-auto flex gap-12 pt-8 flex-wrap items-center justify-center">
        {cloudExpertise.map((technology) => (
          <ImageContainer image={technology.image} clx={technology.clx} />
        ))}
      </div>

      <div className="max-w-6xl mx-auto text-center py-16">
        <div className="madeTommyDark text-3xl">
          Choosing Between Co-location and Public Cloud: A Comprehensive
          Comparison
        </div>
        <div className="text-[#808080] text-base px-12 pt-4">
          In today's digital age, businesses are faced with a critical decision
          when it comes to their IT infrastructure – whether to opt for
          traditional co-location services or embrace the agility and
          scalability of the public cloud. At Blue Orca, we understand that this
          decision can significantly impact your organization's efficiency,
          cost-effectiveness, and overall performance. That's why we're here to
          help you make an informed choice by comparing these two options.
        </div>
      </div>

      <ComplexRibbon
        bgImg={coLocation}
        title={"Co-location:"}
        description={`Co-location, short for "co-location hosting," refers to the practice of housing your servers and networking equipment in a data center facility provided by a third-party provider. Here are some key consider`}
        steps={[
          "Control and Customization",
          "Security",
          "Predictable Costs",
          "Maintenance and Upkeep",
        ]}
      />
      <div className="mt-16">
        <ComplexRibbon
          bgImg={publicCloud}
          title={"Public Cloud:"}
          description={
            "Public cloud services, offered by providers like AWS, Azure, and Google Cloud, deliver scalable, on-demand resources over the internet. Here are some key points to consider:"
          }
          steps={[
            "Scalability",
            "Managed Services",
            "Cost Flexibility",
            "Global Reach",
          ]}
          index={true}
        />
      </div>

      <div className="mt-14 mb-28">
        <div className="text-4xl madeTommyDark text-center">
          Which One is <span className="text-[#0a71f9]">Right</span> for You?
        </div>
        <div className="pt-16 max-w-3xl mx-auto text-lg">
          <div className="text-[#808080] madeTommyDark">
            The choice between co-location and public cloud depends on your
            organization's specific needs, budget, and growth plans. Here are
            some guiding questions to help you decide:
          </div>
          <div className="text-[#808080] pt-14">
            <ul
              className="ml-6"
              style={{
                listStyleType: "disc",
              }}
            >
              <li>
                How much control do you need over your infrastructure?
                Co-location provides more control, while the cloud abstracts
                many infrastructure management tasks.
              </li>
              <li>
                What's your budget? Cloud services offer cost flexibility, but
                co-location can be cost-effective for organizations with stable
                workloads.
              </li>
              <li>
                Are you prepared to manage your infrastructure? Co-location
                requires in-house expertise, while the cloud offloads most
                maintenance tasks to the provider.
              </li>
              <li>
                Do you need to scale rapidly or have a global presence? The
                cloud excels in scalability and global reach.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DynamicRibbon
        bgImg={expertsCallRibbon}
        description={"Book a call with our experts"}
      />
      <div className="mt-12 md:mt-24">
        <GetInTouch />
      </div>
    </div>
  );
};

export default Cloud;

const CustomizedButton = ({ title, clx }) => {
  return (
    <div
      className={`text-base font-bold rounded-[32px] text-white flex items-center justify-center h-[84px] w-[222px] madeTommyDark absolute ${clx}`}
      style={{
        boxShadow: "11.172px 13.566px 17.556px 0px rgba(0, 133, 255, 0.26)",
        background:
          "var(--check, linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%))",
      }}
    >
      {title}
    </div>
  );
};
