import React from "react";
import ChildService from "../../components/ChildService/ChildService";
import cyberSecurity from "../../assets/images/services/cyber-security/cyber-security-services.png";
import { cyberSecurityServices } from "../../utils/services/cyberSecurity";
import CaseStudySection from "../../components/CaseStudySection/CaseStudySection";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const CyberSecurity = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={cyberSecurity}
          alt="cyber security service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Cyber Security Services
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-8 xl:pt-16 pb-8 flex justify-center flex-wrap gap-6 2xl:gap-12 max-w-6xl mx-auto">
        {cyberSecurityServices.map((cyberSecurity) => (
          <ChildService
            title={cyberSecurity.title}
            logo={cyberSecurity.logo}
            mobileLogo={cyberSecurity.mobileLogo}
            url={cyberSecurity.to}
          />
        ))}
      </div>

      <CaseStudySection />

      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>

      <GetInTouch />
    </div>
  );
};

export default CyberSecurity;
