import React from "react";

const DynamicRibbon = ({ bgImg, description, clx }) => {
  console.log(clx, "hi");
  return (
    <div className="relative text-center text-white">
      <img
        src={bgImg}
        className={`h-[102px] lg:h-[216px] ${clx}`}
        alt="Securing Your Digital Future"
      />
      <div className="font-extrabold min-w-max absolute madeTommyDark centerAlign md:w-full top-1/2 text-base md:text-2xl xl:text-4xl">
        {description}
      </div>
    </div>
  );
};

export default DynamicRibbon;
