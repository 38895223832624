import React from "react";
import ChildService from "../../components/ChildService/ChildService";
import dataScience from "../../assets/images/services/data-science/data-science.png";
import { dataScienceServices } from "../../utils/services/dataScience";
import CaseStudySection from "../../components/CaseStudySection/CaseStudySection";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const DataScience = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={dataScience}
          alt="data service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Data Science
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-16 pb-8 flex justify-center flex-wrap gap-6 2xl:gap-12 max-w-6xl mx-auto">
        {dataScienceServices.map((dataScience) => (
          <ChildService
            title={dataScience.title}
            logo={dataScience.logo}
            mobileLogo={dataScience.mobileLogo}
            url={dataScience.to}
          />
        ))}
      </div>

      <CaseStudySection />

      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>

      <GetInTouch />
    </div>
  );
};

export default DataScience;
