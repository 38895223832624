import React from "react";
import datto from "../../assets/images/datto.png";
import azure from "../../assets/images/azure.png";
import cisco from "../../assets/images/cisco.png";
import vmware from "../../assets/images/vmware.png";
import fortinet from "../../assets/images/fortinet.png";
import nutanix from "../../assets/images/nutanix.png";
import googleCloud from "../../assets/images/google-cloud.png";
import sonicWall from "../../assets/images/sonic-wall.png";
import veeam from "../../assets/images/veeam.png";
import salesforce from "../../assets/images/salesforce.png";
import microsoftDynamics from "../../assets/images/microsoft-dynamics.png";
import microsoft365 from "../../assets/images/microsoft-365.png";
import googleWorkspace from "../../assets/images/google-workspace.png";
import bitDefender from "../../assets/images/bit-defender.png";
import paloAlto from "../../assets/images/palo-alto.png";
import meraki from "../../assets/images/meraki.png";
import powerBI from "../../assets/images/power-bi-logo.png";
import azureSynapse from "../../assets/images/azure-synapse.png";
import elastic from "../../assets/images/elastic.png";
import veraCode from "../../assets/images/vera-code.png";
import aws from "../../assets/images/aws.png";
import oracle from "../../assets/images/oracle.png";
import redHAT from "../../assets/images/red-hat.png";
import hp from "../../assets/images/hp.svg";
import mcAfee from "../../assets/images/mc-afee.png";
import avePoint from "../../assets/images/ave-point.png";
import acronics from "../../assets/images/acronics.png";
import snowflake from "../../assets/images/snowflake.png";
import citrix from "../../assets/images/citrix.png";
import Slider from "react-slick";
import "./Technologies.css";
const partners = [
  //row 1
  {
    img: azure,
    props: "w-44 h-[50px] md:w-[180px] md:h-[47px]",
  },
  {
    img: aws,
    props: "w-44 h-[72px] md:w-[116px] md:h-[48px]",
  },
  {
    img: googleCloud,
    props: "w-44 h-8 md:w-[180px] md:h-[29px]",
  },
  {
    img: oracle,
    props: "w-40 h-14 md:w-[135px] md:h-[40px]",
  },

  //row 2
  {
    img: vmware,
    props: "w-[178px] h-[42px] md:w-[180px] md:h-[43px]",
  },
  {
    img: nutanix,
    props: "w-[178px] h-[31px] md:w-[180px] md:h-[23.5px]",
  },
  {
    img: redHAT,
    props: "w-[178px] h-[53px] md:w-[154px] md:h-[46px]",
  },
  {
    img: hp,
    props: "w-20 h-16 md:w-[68px] md:h-[68px] ",
  },

  //row 3
  {
    img: microsoft365,
    props: "w-[176px] h-[36px] md:w-[180px] md:h-[29px]",
  },
  {
    img: googleWorkspace,
    props: "w-[178px] h-[86px] md:w-[154px] md:h-[68px]",
  },
  {
    img: salesforce,
    props: "w-[108px] h-[76px] md:w-[58px] md:h-[41px]",
  },
  {
    img: microsoftDynamics,
    props: "w-[178px] h-[59px] md:w-[135px] md:h-[45px]",
  },

  //row 4
  {
    img: paloAlto,
    props: "w-[178px] h-[45px] md:w-[180px] md:h-[33px]",
  },
  {
    img: fortinet,
    props: "w-[178px] h-[26.4px] md:w-[180px] md:h-[22px]",
  },
  {
    img: cisco,
    props: "w-[145px] h-[75px] md:w-[130px] md:h-[60px]",
  },
  {
    img: sonicWall,
    props: "w-[178px] h-[30.3px] md:w-[180px] md:h-[30px]",
  },

  //row 5
  {
    img: mcAfee,
    props: "w-[178px] h-[42px] md:w-[180px] md:h-[36px]",
  },
  {
    img: meraki,
    props: "w-[176px] h-[63px] md:w-[180px] md:h-[53px]",
  },
  {
    img: bitDefender,
    props: "w-[178px] h-[72px] md:w-[156px] md:h-[68px]",
  },
  {
    img: veraCode,
    props: "w-[178px] h-[41.8px] md:w-[180px] md:h-[32px]",
  },

  //row 6
  {
    img: veeam,
    props: "w-[178px] h-[43.9px] md:w-[180px] md:h-[34px]",
  },
  {
    img: datto,
    props: "w-[178px] h-[58px] md:w-[180px] md:h-[58px]",
  },
  {
    img: avePoint,
    props: "w-[178px] h-[36px] md:w-[180px] md:h-[28px]",
  },
  {
    img: acronics,
    props: "w-[178px] h-[53.4px] md:w-[180px] md:h-[60px]",
  },

  //row 7
  {
    img: powerBI,
    props: "w-[178px] h-[49px] md:w-[180px] md:h-[50px]",
  },
  {
    img: azureSynapse,
    props: "w-[178px] h-[99.7px] md:w-[133px] md:h-[80px]",
  },
  {
    img: elastic,
    props: "w-[178px] h-[60.8px] md:w-[180px] md:h-[60px]",
  },
  {
    img: snowflake,
    props: "w-[178px] h-[53.4px] md:w-[180px] md:h-[48px]",
  },

  {
    img: citrix,
    props: "w-[178px] h-[53.4px] md:w-[120px] md:h-[80px]",
  },
];
const Technologies = ({ removeHeader }) => {
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    autoplay: false,
    arrows: true,
  };

  const set = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    speed: 300,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",

    // responsive: [
    //   {
    //     breakpoint: 1250,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,
    //       infinite: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  let set1 = {
    ...set,
    rtl: true,
  };

  return (
    <div className="p-6 md:py-24 md:px-0 mx-auto max-w-full">
      <div className="font-black text-xl md:text-4xl text-center madeTommyDark">
        <span className={`${removeHeader && "hidden"}`}>
          Technology Expertise
        </span>
        <div className="mt-5 md:hidden">
          <Slider {...settings}>
            {partners.map((partner) => (
              <Partner partner={partner} />
            ))}
          </Slider>
        </div>
        <div className="partner hidden mt-12 md:block">
          <Slider {...set}>
            {partners.map((partner) => (
              <Partner partner={partner} />
            ))}
          </Slider>
        </div>
        <div className="partner hidden md:block">
          <Slider {...set1}>
            {partners
              .slice(0)
              .reverse()
              .map((partner) => (
                <Partner partner={partner} />
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Technologies;

const Partner = ({ partner }) => {
  return (
    <div
      className="border border-gray-200 px-3 w-5/6 md:px-0 md:border-0 rounded-lg md:w-[215px] h-20 !flex items-center justify-center hover:scale-110"
      style={{
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        transition: "transform .6s",
        display: "inline-block",
      }}
    >
      <img src={partner?.img} className={`${partner.props} img`} />
    </div>
  );
};
