import React, { Fragment, useRef, useState } from "react";
import fname from "../assets/images/contact-us/fname.svg";
import fnamemWeb from "../assets/images/contact-us/fname-mweb.svg";
import lname from "../assets/images/contact-us/lname.svg";
import lnamemWeb from "../assets/images/contact-us/lname-mweb.svg";
import jobTitle from "../assets/images/contact-us/job-title.svg";
import jobTitlemWeb from "../assets/images/contact-us/job-title-mweb.svg";
import workEmail from "../assets/images/contact-us/work-email.svg";
import workEmailmWeb from "../assets/images/contact-us/work-email-mweb.svg";
import companyName from "../assets/images/contact-us/company-name.svg";
import companyNamemWeb from "../assets/images/contact-us/company-name-mweb.svg";
import contactUsTitle from "../assets/images/contact-us/contact-us-title.png";
import blueOrca from "../assets/images/contact-us/blue-orca-contact.svg";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();
  const YOUR_PUBLIC_KEY = "K0ov3BEiLlDxihdbI";
  const serviceID = "default_service";
  const templateID = "book_meeting";
  const [submitButtonText, setSubmitButtonText] = useState("Get in touch");

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitButtonText("Sending ...");
    console.log(form);
    emailjs.sendForm(serviceID, templateID, form.current, YOUR_PUBLIC_KEY).then(
      (result) => {
        setSubmitButtonText("Sent");
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 3000);
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img
          src={contactUsTitle}
          alt="contact us"
          className="h-[140px] md:h-auto"
        />
        <div className="font-extrabold absolute centerAlign top-[60%] text-xl md:text-4xl">
          Contact Us
        </div>
      </div>

      <div className="pt-8 xl:pt-24 mx-auto w-[74vw] md:w-[64vw]">
        <div className="text-sm md:text-4xl madeTommyDark">
          About <span className="gradientText">Blue</span> Orca Solutions
        </div>
        <div className="text-lg text-[#808080] flex gap-5 flex-col">
          <div className="flex gap-12">
            <div>
              <p className="my-4">
                Blue Orca Solutions, a trusted leader in IT professional
                services, empowers businesses with cutting-edge technology to
                achieve their goals. With a skilled team of experts, we
                specialise in delivering tailored solutions in cloud computing,
                cybersecurity, data management, and more.
              </p>
              <p>
                Our commitment to excellence has earned us a strong reputation,
                having successfully executed numerous projects in various
                industries.
              </p>
            </div>
            <img src={blueOrca} className="ms-12 me-24" />
          </div>

          <p>
            As a company rooted in Canada, we have proudly served businesses
            across the region, delivering transformative results that drive
            growth and success. Our deep understanding of the Canadian business
            landscape allows us to navigate unique challenges and provide
            industry-specific insights to our valued clients.
          </p>

          <p>
            At Blue Orca Solutions, we prioritize cybersecurity as a fundamental
            aspect of all our services. Our dedicated team of cybersecurity
            professionals ensures the protection of valuable data and assets
            against evolving threats and potential breaches. By leveraging
            state-of-the-art security measures and advanced encryption
            technologies, we safeguard critical business information.
          </p>

          <p>
            Continuously at the forefront of technological advancements, we
            offer the latest innovations in cloud computing, data analytics,
            artificial intelligence, and more. By harnessing the power of these
            cutting-edge technologies, we enable businesses to optimize
            operations, enhance efficiency, and gain a competitive edge.
          </p>
          <p>
            Choose Blue Orca Solutions as your trusted IT partner, and
            experience our unwavering commitment to your success. We deliver
            exceptional service, reliable support, and collaborative
            partnerships that ensure your technology investments yield the best
            possible outcomes. Join us on the transformative journey towards
            digital excellence.
          </p>
        </div>

        <div className="text-sm md:text-4xl madeTommyDark py-12" id="contact-us">
          Contact Us
        </div>
        <form className="flex flex-col gap-7" ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col gap-7 md:gap-0 md:flex-row justify-between w-full">
            <div className="relative w-full md:w-[48%]">
              <label className="text-[10px] md:text-sm font-bold text-[#AAAAAA]">
                First Name*
              </label>
              <input
                type="text"
                placeholder="John"
                name="first_name"
                required
                className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
                style={{
                  boxShadow:
                    "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
                }}
              />
              <img
                src={window.innerWidth >= 768 ? fname : fnamemWeb}
                className="absolute top-9 md:top-12 left-6"
              />
            </div>

            <div className="relative w-full md:w-[50%]">
              <label className="text-[10px] md:text-sm  font-bold text-[#AAAAAA]">
                Last Name*
              </label>
              <input
                type="text"
                placeholder="Miller"
                name="last_name"
                required
                className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
                style={{
                  boxShadow:
                    "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
                }}
              />
              <img
                src={window.innerWidth >= 768 ? lname : lnamemWeb}
                className="absolute top-9 md:top-12 left-6"
              />
            </div>
          </div>

          <div className="relative">
            <label className="text-[10px] md:text-sm font-bold text-[#AAAAAA]">
              Job Title*
            </label>
            <input
              type="text"
              name="job_title"
              required
              placeholder="UX Engineer"
              className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
              style={{
                boxShadow:
                  "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
              }}
            />
            <img
              src={window.innerWidth >= 768 ? jobTitle : jobTitlemWeb}
              className="absolute top-9 md:top-12 left-6"
            />
          </div>

          <div className="relative">
            <label className="text-[10px] md:text-sm font-bold text-[#AAAAAA]">
              Work Email*
            </label>
            <input
              type="email"
              placeholder="abc@email.com"
              name="work_email"
              required
              className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
              style={{
                boxShadow:
                  "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
              }}
            />
            <img
              src={window.innerWidth >= 768 ? workEmail : workEmailmWeb}
              className="absolute top-9 md:top-12 left-6"
            />
          </div>

          <div className="relative">
            <label className="text-[10px] md:text-sm font-bold text-[#AAAAAA]">
              Phone Number*
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              name="phone_number"
              required
              className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
              style={{
                boxShadow:
                  "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
              }}
            />
            <img
              src={window.innerWidth >= 768 ? workEmail : workEmailmWeb}
              className="absolute top-9 md:top-12 left-6"
            />
          </div>

          <div className="relative">
            <label className="text-[10px] md:text-sm font-bold text-[#AAAAAA]">
              Company Name*
            </label>
            <input
              type="text"
              placeholder="Company Name"
              name="company_name"
              required
              className="pl-16 md:pl-20 w-full h-11 md:h-20 rounded-2xl border-0"
              style={{
                boxShadow:
                  "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
              }}
            />
            <img
              src={window.innerWidth >= 768 ? companyName : companyNamemWeb}
              className="absolute top-9 md:top-12 left-6"
            />
          </div>

          <textarea
            placeholder="Questions or Comments"
            className="p-5 w-full border-0 rounded-2xl h-64"
            required
            style={{
              boxShadow:
                "9px 10px 19.7363px -2.46703px rgba(141, 141, 141, 0.3)",
              resize: "none",
            }}
            name="message"
          ></textarea>

          <button
            style={{
              background:
                submitButtonText == "Sent"
                  ? "gray"
                  : "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
              alignSelf: "end",
            }}
            disabled={submitButtonText == "Sent" ? true : false}
            className="w-28 h-10 md:w-48 md:h-20 rounded-3xl cursor-pointer font-extrabold text-[10px] text-white md:text-lg relative"
          >
            {submitButtonText}
          </button>
        </form>
        <div id="snackbar">Message sent successfully!</div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
