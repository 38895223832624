import React, { Fragment } from "react";
import caseStudies from "../../assets/images/case-studies/case-studies.png";
import studyOne from "../../assets/images/case-studies/study-one.png";
import studyTwo from "../../assets/images/case-studies/study-two.png";
import studyThree from "../../assets/images/case-studies/study-three.png";
import studyFour from "../../assets/images/case-studies/study-four.png";
import studyFive from "../../assets/images/case-studies/study-five.png";
import studySix from "../../assets/images/case-studies/study-six.png";
import studySeven from "../../assets/images/case-studies/study-seven.png";
import studyEight from "../../assets/images/case-studies/study-eight.png";
import studyNine from "../../assets/images/case-studies/study-nine.png";
import studyTen from "../../assets/images/case-studies/study-ten.png";
import studyEleven from "../../assets/images/case-studies/study-eleven.png";
import studyTwelve from "../../assets/images/case-studies/study-twelve.png";
import studyThirteen from "../../assets/images/case-studies/study-thirteen.png";
import studyFourteen from "../../assets/images/case-studies/study-fourteen.png";
import "./style.css";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";

const CaseStudies = () => {

  const caseStudiesArr = [
    {
      img: studyOne,
      title: "Streamlining IT Operations for a Leading Logistics...",
      link: "/it-operations",
      clx: "h-[96px] md:max-w-[682px] md:h-[178px]",
    },
    {
      img: studyTwo,
      title: "Enhancing IT Support for a ...",
      clx: "h-[96px] md:max-w-[442px] md:h-[178px]",
    },
    {
      img: studyThree,
      title: "Red Teaming Exercise for a Leading BPO Company in Canada",
    },
    {
      img: studyFour,
      title: "Penetration Testing and ...",
      clx: "w-[442px] h-[178px]",
    },
    {
      img: studyFive,
      title: "BOT Analysis for an E-commerce Website",
      clx: "w-[682px] h-[178px]",
    },
    {
      img: studySix,
      title: "Static Code Analysis and Remediation for a ...",
    },
    {
      img: studySeven,
      title: "Static Code Analysis and...",
    },
    {
      img: studyEight,
      title:
        "SOC 1 and SOC 2 Compliance Readiness for a Financial Services Firm",
    },
    {
      img: studyNine,
      title: "ISO 27001 Compliance for a ...",
    },
    {
      img: studyTen,
      title: "Website Remediation and Removal of Webshells ...",
    },
    {
      img: studyEleven,
      title: "Cloud Migration and Microsoft Tenant Hardening...",
    },
    {
      img: studyTwelve,
      title: "CI/CD Pipeline Implementation...",
    },
    {
      img: studyThirteen,
      title: "Power BI Dashboard for an Energy Company in Calgary",
    },
    {
      img: studyFourteen,
      title: "ETL Use Case with Snowflake ...",
    },
    {
      img: studyFive,
      title: "BOT Analysis for an E-commerce Website",
      clx: "w-[682px] h-[178px]",
    },
  ];
  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img
          src={caseStudies}
          alt="contact us"
          className="h-[140px] md:h-auto"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Case Studies
        </div>
      </div>

      <Breadcrumb />
      <div className="mt-4 md:mt-16 flex flex-wrap gap-[26px] max-w-6xl mx-auto">
        {caseStudiesArr.map((study) => (
          <ImageContainer
            image={study.img}
            title={study.title}
            url={study.link}
            clx={study.clx}
          />
        ))}
      </div>
      <div className="mt-12 md:mt-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>
      <div className="mt-16">
        <GetInTouch />
      </div>
    </Fragment>
  );
};

const ImageContainer = ({ image, title, url, clx }) => {
  return (
    <Link to={url}>
      <div className="inline-block overflow-hidden relative rounded-xl cursor-pointer">
        <div className="mainGroupImage">
          <img src={image} alt={`${image}`} className={clx} />
        </div>
        <div className="absolute text-white bottom-2 left-2 md:bottom-6 md:left-5 font-bold madeTommyDark text-xs md:text-lg">
          {title}
        </div>
      </div>
    </Link>
  );
};

export default CaseStudies;
