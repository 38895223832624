import React from "react";
import { HashLink } from "react-router-hash-link";

const ChildService = ({ title, logo, mobileLogo, url }) => {
  return (
    <HashLink to={url}>
      <div
        id="text"
        className="w-36 md:w-44 lg:w-60 h-[15.635rem] lg:h-[26rem] rounded-3xl text-white flex flex-col justify-end pl-3 pr-2 cursor-pointer"
        style={{
          backgroundImage:
            window.innerWidth >= 768 ? `url(${logo})` : `url(${mobileLogo})`,
          backgroundRepeat: "no-repeat",
          border: "0.2px solid white",
        }}
      >
        <div className="text-md lg:text-3xl font-extrabold text-center madeTommyDark relative bottom-[40%]">
          <strong>{title}</strong>
        </div>
      </div>
    </HashLink>
  );
};

export default ChildService;
