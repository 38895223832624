import managedItServicesLogo from "../assets/images/managed-it-services.svg";
import applicationModeranization from "../assets/images/application-moderanization.svg";
import devOps from "../assets/images/dev-ops.svg";
import cyberSecurity from "../assets/images/cyber-security.svg";
import cloudServices from "../assets/images/cloud-services.svg";
import dataScience from "../assets/images/data-science.svg";

export const services = [
  {
    title: "Managed IT Services",
    description:
      "Comprehensive support for your business needs. Our certified professionals ensure that your IT infrastructure runs smoothly and efficiently",
    logo: managedItServicesLogo,
    url: "services/managed-i-t-services",
  },
  {
    title: "Cloud Services",
    description:
      "Cloud Services, including Infrastructure, Migration, Management, Disaster Recovery, Security, Consulting, and DevOps.",
    logo: cloudServices,
    url: "services/cloud-service",
  },
  {
    title: "Cyber Security",
    description:
      "Protecting your IT infrastructure from cyber threats. We offer vulnerability assessment and penetration testing, and network security.",
    logo: cyberSecurity,
    url: "services/cyber-security",
  },
  {
    title: "DevOps Services",
    description:
      "DevOps services to automate software development and deployment, improve collaboration, and optimize workflows, resulting in faster software delivery and improved quality.",
    logo: devOps,
    url: "services/devops",
  },
  {
    title: "Application Modernization",
    description:
      "Application Development and Modernization services for legacy applications, as well as cloud-native application development.",
    logo: applicationModeranization,
    url: "services/application-modernization",
  },
  {
    title: "Data Sciences and Analytics",
    description:
      "Using advanced analytics tools and techniques, we help you gain valuable insights from your data, build predictive models, and make informed business decisions.",
    logo: dataScience,
    url: "services/data-science",
  },
];
