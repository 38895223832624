import React from "react";
import { Link } from "react-router-dom";

const CustomSection = ({
  image,
  heading,
  subHeading,
  description = "",
  reverse = 0,
  to = "",
}) => {
  return (
    <div
      className={`py-6 md:py-12 px-6 md:px-0 max-w-6xl mx-auto font-black text-xl md:text-3xl text-center flex flex-col ${
        reverse == 1 ? "lg:flex-row-reverse" : "lg:flex-row"
      } justify-center items-center gap-x-24 gap-y-12`}
    >
      <img src={image} className="w-[222px] h-[191px] md:w-auto md:h-auto" />
      <div className="flex flex-col items-center">
        <div className="text-sm md:text-4xl">{heading}</div>
        <div className="text-sm md:text-xl mt-3">{subHeading}</div>
        <div className="text-xs md:text-xl font-extralight text-[#6E6E6E] my-6 md:w-96">
          {description}
        </div>
        <Link to={to}>
          <div
            className="hidden md:flex w-48 text-white text-xl h-20 items-center justify-center rounded-2xl cursor-pointer"
            style={{
              background: "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
            }}
          >
            Learn More
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CustomSection;
