import React from "react";

const ComplexRibbon = ({ bgImg, title, description, steps, index = 0 }) => {
  return (
    <div
      className="text-white flex flex-col justify- pl-3 pr-2 cursor-pointer h-96"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        border: "0.2px solid white",
      }}
    >
      <div className="max-w-6xl mx-auto text-center">
        <div className="text-md lg:text-3xl font-extrabold madeTommyDark mt-14">
          <strong>{title}</strong>
        </div>
        <div className="text-lg pt-8">{description}</div>
        <div className="text-lg pt-8">
          <div className="bg-white w-6 h-6 rounded-full text-blue-700 font-bold inline-block relative">
            1
          </div>
          <div className={`absolute z-10 ${index ? "ml-40" : "ml-16"}`}>
            {steps[0]}
          </div>
          <span className="bg-white w-[230px] h-[2px] inline-block relative -top-2"></span>

          <div className="bg-white w-6 h-6 rounded-full text-blue-700 font-bold inline-block relative">
            2
          </div>
          <div
            className={`absolute z-10 ${index ? "ml-[370px]" : "ml-[420px]"}`}
          >
            {steps[1]}
          </div>
          <span className="bg-white w-[230px] h-[2px] inline-block relative -top-2"></span>

          <div className="bg-white w-6 h-6 rounded-full text-blue-700 font-bold inline-block relative">
            3
          </div>
          <div
            className={`absolute z-10 ${index ? "ml-[650px]" : "ml-[620px]"}`}
          >
            {steps[2]}
          </div>
          <span className="bg-white w-[230px] h-[2px] inline-block relative -top-2"></span>

          <div className="bg-white w-6 h-6 rounded-full text-blue-700 font-bold inline-block relative">
            4
          </div>

          <div
            className={`absolute z-10 ${index ? "ml-[900px]" : "ml-[850px]"}`}
          >
            {steps[3]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplexRibbon;
