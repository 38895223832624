import React, { Fragment, useEffect, useState } from "react";
import cyberSecurity from "../../assets/images/services/cyber-security/cyber-security-services.png";
import devSecOps from "../../assets/images/services/cyber-security/dev-sec-ops.png";
import attributes from "../../assets/images/services/cyber-security/attributes.png";
import complianceRisk from "../../assets/images/services/cyber-security/compliance-risk-mgmt.png";
import firewall from "../../assets/images/services/cyber-security/firewall-assessment.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CaseStudySection from "../../components/CaseStudySection/CaseStudySection";
import { cyberSecurityServices } from "../../utils/services/cyberSecurity";
import DetailChildService from "../../components/DetailChildService/DetailChildService";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import AnimatedRibbon from "../../components/AnimatedRibbon/AnimatedRibbon";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import paloAlto from "../../assets/images/palo-alto.png";
import cisco from "../../assets/images/cisco.png";
import fortinet from "../../assets/images/fortinet.png";
import sonicWall from "../../assets/images/sonic-wall.png";
import m365Banner from "../../assets/images/services/cyber-security/m365-banner.png";
import m365Logo from "../../assets/images/services/cyber-security/m365-logo.png";
import azureLogo from "../../assets/images/services/cyber-security/azure-logo.png";
import wordLogo from "../../assets/images/services/cyber-security/word-logo.png";
import teamsLogo from "../../assets/images/services/cyber-security/teams-logo.png";
import sLogo from "../../assets/images/services/cyber-security/s-logo.png";
import cloudLogo from "../../assets/images/services/cyber-security/cloud-logo.png";
import vCISORibbon from "../../assets/images/services/cyber-security/vciso-ribbon.png";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import GetInTouch from "../../components/GetInTouch/GetInTouch";

const CyberSecurity = () => {
  const [progressValues, setProgressValues] = useState({
    testingProjects: 0,
    assessments: 0,
    remediation: 0,
    trainings: 0,
  });

  const imageContainer = [
    {
      img: paloAlto,
      clx: "w-[137px] h-[35px]",
    },
    {
      img: cisco,
      clx: "w-[78px] h-[40px]",
    },
    {
      img: fortinet,
      clx: "w-[152px] h-[22.6px]",
    },
    {
      img: sonicWall,
      clx: "w-[140px] h-[30px]",
    },
  ];

  useEffect(() => {
    const duration = 5000; // Animation duration in milliseconds
    const startValue = {
      testingProjects: 0,
      assessments: 0,
      remediation: 0,
      trainings: 0,
    };
    const endValue = {
      testingProjects: 150,
      assessments: 150,
      remediation: 122,
      trainings: 50,
    };

    let startTimestamp = null;

    const animateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      let animatedValues = {};

      // loop over the object
      Object.keys(endValue).forEach(function (key, index) {
        animatedValues = {
          ...animatedValues,
          [key]: Math.floor(
            progress * (endValue[key] - startValue[key]) + startValue[key]
          ),
        };
      });

      setProgressValues(animatedValues);

      if (progress < 1) {
        window.requestAnimationFrame(animateValue);
      }
    };

    window.requestAnimationFrame(animateValue);
  }, []);

  const logos = [wordLogo, teamsLogo, sLogo, cloudLogo];

  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img
          src={cyberSecurity}
          alt="cyber security service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Cyber Security Services
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-8 xl:pt-16 pb-8 flex justify-center flex-wrap gap-10 max-w-6xl mx-auto">
        {cyberSecurityServices
          .filter((service) => service.description)
          .map((itService, key) => (
            <DetailChildService
              id={itService.id}
              logo={itService.mobileLogo}
              title={itService.title}
              description={itService.description}
              uuid={itService.uuid}
            />
          ))}
      </div>

      <DynamicRibbon
        bgImg={devSecOps}
        description={"DevSecOps (Development, Security, and Operations)"}
        clx={"!h-[148px]"}
      />

      <div className="max-w-6xl mx-auto my-5 text-[#808080] text-center text-lg">
        At Blue Orca Solutions, we offer cutting-edge{" "}
        <span className="gradientText font-bold madeTommyDark">DevSecOps</span>{" "}
        services to ensure your software development process is fortified with
        top-notch security measures.
        <br />
        <div className="font-bold madeTommyDark pt-5">
          With our DevSecOps approach, we seamlessly integrate security
          practices throughout the entire development lifecycle, collaborating
          closely with development and operations teams.
        </div>
        <br />
        Our expert team emphasizes early identification and mitigation of
        potential vulnerabilities, reducing the risk of security breaches and
        enhancing your software's reliability. By embedding security into the
        development process, we accelerate software delivery while maintaining a
        strong focus on security best practices.
        <br />
        <br />
        Through automation, continuous integration, and continuous deployment,
        our DevSecOps methodology streamlines development and deployment
        processes, ensuring a seamless and secure software delivery.
        <div className="font-bold madeTommyDark pt-5">
          Partner with us for top-tier DevSecOps services and achieve enhanced
          security and efficiency in your software development journey.
        </div>
      </div>

      <AnimatedRibbon
        bgImg={attributes}
        title={"Attributes"}
        attributes={[
          {
            title: "Testing Projects",
            value: progressValues.testingProjects,
          },
          {
            title: "Assessments",
            value: progressValues.assessments,
          },
          {
            title: "Remediation",
            value: progressValues.remediation,
          },
          {
            title: "Trainings",
            value: progressValues.trainings,
          },
        ]}
      />

      <div className="mt-10" id="compliance-risk-management">
        <DynamicRibbon
          bgImg={complianceRisk}
          description={"Compliance and Risk Management"}
          clx={"!h-[148px]"}
        />
      </div>
      <div className="max-w-6xl mx-auto my-5 text-[#808080] text-center text-lg">
        Our Cyber Security services include compliance and risk management to
        ensure that your IT infrastructure is compliant with industry
        regulations and standards.
        <br />
        <br />
        <div>
          We can help you assess your compliance posture, implement risk
          management frameworks, and develop security policies and procedures to
          mitigate risks and ensure compliance.
        </div>
        <ul
          style={{
            listStyleType: "disc",
          }}
          className="flex space-x-8 items-center justify-center text-black font-bold py-10"
        >
          <li>
            Control Frameworks <span className="gradientText">ISO 27001</span>
          </li>
          <li>
            Control Objectives for{" "}
            <span className="gradientText">IT (COBIT)</span>
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <DynamicRibbon
          bgImg={firewall}
          description={"Firewall Audit and Assessment"}
          clx={"!h-[148px]"}
        />
      </div>
      <div className="max-w-6xl mx-auto my-5 text-[#808080] text-center text-lg">
        At Blue Orca Solutions, we offer a wide range of firewall-related
        services designed to strengthen your network security and protect your
        critical assets. With our extensive experience in leading firewall
        brands like{" "}
        <span className="gradientText font-bold madeTommyDark">
          Cisco, Palo Alto, Fortinet,
        </span>{" "}
        and{" "}
        <span className="gradientText font-bold madeTommyDark">SonicWall,</span>{" "}
        we can provide tailored solutions that meet your specific security
        requirements.
        <div className="flex justify-between p-16">
          {imageContainer.map((image) => (
            <ImageContainer image={image.img} clx={image.clx} />
          ))}
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-center space-x-5 space-y-5 max-w-[1214px] mx-auto">
        <div
          style={{
            boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
            borderRadius: 40,
          }}
          className="p-5 text-black text-left w-[30%]"
        >
          <div className="text-xl font-black madeTommyDark md:min-w-max">
            Firewall Deployment and Migration:
          </div>{" "}
          <div className="text-lg pt-5">
            Our team of experts is well-versed in{" "}
            <span className="gradientText">deploying</span> and{" "}
            <span className="gradientText">migrating firewalls</span> across
            various brands. Whether you're looking to implement a new firewall
            or upgrade your existing one, we have the knowledge and skills to
            ensure a <span className="gradientText">smooth</span> and{" "}
            <span className="gradientText">secure transition.</span>
          </div>
        </div>

        <div
          style={{
            boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
            borderRadius: 40,
          }}
          className="p-5 text-black text-left md:w-[35%]"
        >
          <div className="text-xl font-black madeTommyDark md:min-w-max">
            Next-Generation Firewall Configuration:
          </div>{" "}
          <div className="text-lg pt-5">
            Take advantage of advanced security features with Next-Generation
            Firewalls{" "}
            <span className="gradientText madeTommyDark">(NGFW).</span> Brands
            like <span className="gradientText">Palo Alto, Fortinet</span>,
            and&nbsp;<span className="gradientText">Cisco</span> offer NGFW
            solutions that provide application awareness, user-based policies,
            and intrusion prevention, offering enhanced protection against
            modern cyber threats.
          </div>
        </div>

        <div
          style={{
            boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
            borderRadius: 40,
          }}
          className="p-5 text-black w-[30%]"
        >
          <div className="text-xl font-black madeTommyDark md:min-w-max">
            Firewall Audits and Assessments:
          </div>{" "}
          <div className="text-lg pt-5 text-left">
            Ensure your firewall is functioning optimally and in compliance with
            security standards. Our thorough{" "}
            <span className="gradientText">firewall audits</span> and
            <span className="gradientText">
              assessments identify vulnerabilities, weaknesses,
            </span>{" "}
            and areas for improvement, helping you{" "}
            <span className="gradientText">fortify your network defenses.</span>
          </div>
        </div>

        <div
          style={{
            boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
            borderRadius: 40,
          }}
          className="p-5 text-black w-[38%]"
        >
          <div className="text-xl font-black madeTommyDark md:min-w-max">
            Unified Threat Management (UTM) Solutions:
          </div>{" "}
          <div className="text-lg pt-5 text-left">
            Blue Orca Solutions can help you{" "}
            <span className="gradientText">implement UTM solutions</span>{" "}
            tailored to your business needs,{" "}
            <span className="gradientText">
              simplifying security management
            </span>{" "}
            and reducing potential attack vectors.
          </div>
        </div>

        <div
          style={{
            boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
            borderRadius: 40,
          }}
          className="p-5 text-black w-[45%]"
        >
          <div className="text-xl font-black madeTommyDark md:min-w-max">
            Intrusion Prevention and Detection Systems (IPS/IDS):
          </div>{" "}
          <div className="text-lg pt-5 text-left">
            {/* description */}
            Protect your network against intrusions with{" "}
            <span className="gradientText">IPS/IDS</span> solutions. Brands
            like&nbsp;
            <span className="gradientText">
              Cisco, Fortinet, and Palo Alto
            </span>{" "}
            offer robust IPS/IDS products that provide{" "}
            <span className="gradientText">real-time threat detection</span> and
            prevention, helping you stay ahead of cyber threats.
          </div>
        </div>
      </div>

      <div className="text-center text-[#808080] text-lg font-bold my-16 max-w-6xl mx-auto">
        At Blue Orca Solutions, we understand that each business has unique
        security requirements. Our expertise across multiple firewall brands and
        their best-known security products enables us to provide you with the
        most effective and customized security solutions to keep your business
        protected from today's evolving cyber threats.
      </div>
      <div className="relative text-center text-white">
        <img
          src={m365Banner}
          alt={"Microsoft 365 (M365) and Azure Security Enhancement"}
          className="h-[102px] md:h-auto"
        />
        <div className="font-extrabold madeTommyDark absolute centerAlign md:w-full top-1/2 md:top-1/3 lg:top-1/4 text-base md:text-2xl xl:text-4xl">
          {"Microsoft 365 (M365) and Azure Security Enhancement"}
        </div>
        <span className="text-xs md:text-xl xl:text-2xl absolute centerAlign hidden lg:block top-[60%] md:top-[55%] w-72 md:w-[80%] lg:w-auto xl:w-[50rem]">
          {
            "Elevate Your Cloud Security to the Next Level with Blue Orca Solutions, Your Trusted Microsoft Partner"
          }
        </span>
      </div>

      <div className="mt-12">
        {/* section */}
        <div className="flex mx-auto max-w-6xl justify-between">
          <div>
            <img
              src={m365Logo}
              alt={"Microsoft 365 (M365) and Azure Security Enhancement"}
              className="w-[510px] h-[320px]"
            />
            <div
              style={{
                boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
                borderRadius: 40,
              }}
              className="p-5 text-black w-[80%] mx-auto"
            >
              <div className="text-xl font-black madeTommyDark md:min-w-max">
                M365 Security Enhancement:
              </div>{" "}
              <div className="text-lg pt-5 text-left pl-4">
                {/* description */}
                <ul style={{ listStyleType: "disc" }}>
                  <li>Multi-Factor Authentication (MFA) Implementation</li>
                  <li>Anti-Phishing and Anti-Malware Solutions</li>
                  <li>Secure Mail and File Sharing Configurations</li>
                  <li>User Training for Best Security Practices</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <img
              src={azureLogo}
              alt={"azure logo"}
              className="w-[280px] h-[270px] mb-12 mx-auto"
            />
            <div
              style={{
                boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
                borderRadius: 40,
              }}
              className="p-5 text-black w-[98%] mx-auto"
            >
              <div className="text-xl font-black madeTommyDark md:min-w-max">
                Azure Security Hardening:
              </div>{" "}
              <div className="text-lg pt-5 text-left pl-4">
                {/* description */}
                <ul style={{ listStyleType: "disc" }}>
                  <li>Identity and Access Management</li>
                  <li>Conditional Access Policy Setup</li>
                  <li>Data Encryption and Classification</li>
                  <li>Network Security and Firewall Configuration</li>
                  <li>Ongoing Monitoring and Threat Detection</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="logos flex justify-between max-w-6xl mx-auto my-10">
          {logos.map((logo) => (
            <div
              className="w-[185px] h-[160px] bg-white flex items-center justify-center rounded-2xl"
              style={{
                boxShadow: "0px 2.153px 13.458px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <img src={logo} />
            </div>
          ))}
        </section>
        <CaseStudySection />

        <section className="relative text-center text-white">
          <img
            src={vCISORibbon}
            alt={"vCISO"}
            className="h-[102px] md:h-auto"
          />
          <div className="font-extrabold madeTommyDark absolute centerAlign md:w-full top-1/2 md:top-1/3 lg:top-1/4 text-base md:text-2xl xl:text-4xl">
            {"vCISO"}
          </div>
          <span className="text-xs md:text-xl xl:text-2xl absolute centerAlign hidden lg:block top-[60%] md:top-[55%] w-72 md:w-[80%] lg:w-auto xl:w-[50rem]">
            {"Unlocking Your Vision with VCISO: Innovate, Secure, Succeed."}
          </span>
        </section>

        <div className="mt-12 md:mt-24">
          <DynamicRibbon
            bgImg={expertsCallRibbon}
            description={"Book a call with our experts"}
          />
        </div>
        <div className="mt-12 md:mt-24">
          <GetInTouch />
        </div>
      </div>
    </Fragment>
  );
};

export default CyberSecurity;
