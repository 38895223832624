import dataAnalysis from "../../assets/images/services/data-science/data-analysis.png";
import dataAnalysismWeb from "../../assets/images/services/data-science/data-analysis-mweb.png";
import predictiveModeling from "../../assets/images/services/data-science/predictive-modeling.png";
import predictiveModelingmWeb from "../../assets/images/services/data-science/predictive-modeling-mweb.png";
import dataVisualization from "../../assets/images/services/data-science/data-visualization.png";
import dataVisualizationmWeb from "../../assets/images/services/data-science/data-visualization-mweb.png";
import ML from "../../assets/images/services/data-science/machine-learning.png";
import MLmWeb from "../../assets/images/services/data-science/machine-learning-mweb.png";
import NLP from "../../assets/images/services/data-science/NLP.png";
import NLPmWeb from "../../assets/images/services/data-science/NLP-mweb.png";
import dataGovernance from "../../assets/images/services/data-science/data-governance.png";
import dataGovernancemWeb from "../../assets/images/services/data-science/data-governance-mweb.png";
import powerBI from "../../assets/images/services/data-science/power-bi-service.png";

export const dataScienceServices = [
  {
    id: 1,
    title: "Data Analysis",
    logo: dataAnalysis,
    mobileLogo: dataAnalysismWeb,
    description: `Our Data Science services provide comprehensive data analysis for your business needs.
    <br>We use advanced analytics tools and techniques to analyze your data, providing valuable insights and recommendations to help you make informed business decisions.`,
    to: "/services/data-science/detailed-data-science#data-analysis",
    uuid: "data-analysis",
  },
  {
    id: 2,
    title: "Predictive Modeling",
    logo: predictiveModeling,
    mobileLogo: predictiveModelingmWeb,
    description: `Our Data Science services include predictive modeling, enabling you to make accurate predictions about future trends and events. We use statistical modeling techniques and machine learning algorithms to build predictive models that can help you forecast future outcomes and make data-driven decisions.`,
    to: "/services/data-science/detailed-data-science#predictive-modeling",
    uuid: "predictive-modeling",
  },
  {
    id: 3,
    title: "Data Visualization",
    logo: dataVisualization,
    mobileLogo: dataVisualizationmWeb,
    description: `Our Data Science services provide data visualization, enabling you to present your data in a clear and engaging way. We use a range of visualization tools and techniques to create compelling visualizations that help you communicate complex data insights to stakeholders.`,
    to: "/services/data-science/detailed-data-science#data-visualization",
    uuid: "data-visualization",
  },
  {
    id: 4,
    title: "Machine Learning",
    logo: ML,
    mobileLogo: MLmWeb,
    description: `Our Data Science services include machine learning, enabling you to automate and optimize your business processes. We use machine learning algorithms to build predictive models and automate decision-making processes, improving efficiency and accuracy.`,
    to: "/services/data-science/detailed-data-science#machine-learning",
    uuid: "machine-learning",
  },
  {
    id: 5,
    title: "Natural Language Processing",
    logo: NLP,
    mobileLogo: NLPmWeb,
    description: `Our Data Science services include natural language processing, enabling you to extract insights from unstructured data, such as text and speech.
    <br>We use advanced techniques to analyze and interpret natural language data, providing valuable insights that can inform business decisions.`,
    to: "/services/data-science/detailed-data-science#nlp",
    uuid: "nlp",
  },
  {
    id: 6,
    title: "Data Governance and Management",
    logo: dataGovernance,
    mobileLogo: dataGovernancemWeb,
    description: `Our Data Science services provide data engineering, ensuring that your data is properly collected, processed, and stored. We can help you design and implement data pipelines that enable you to collect, clean, and transform data, ensuring its quality and reliability.`,
    to: "/services/data-science/detailed-data-science#data-management",
    uuid: "data-management",
  },
  {
    id: 7,
    title: "Power Bi (COE)",
    logo: powerBI,
    mobileLogo: powerBI,
    to: "/power-bi",
  },
];
