import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import testimonialOne from "../../assets/images/testimonial-one.svg";
import svgIcon from '../../assets/images/icon.png'

const Card = ({ imagen, title, designation, description }) => {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });

  console.log(imagen, title, "123");
  return (
    <animated.div
      className={`flex flex-col justify-center bg-white w-[16rem] md:w-[36rem] min-h-fit rounded-xl p-4 md:p-8 pt-0`}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className="flex items-center justify-center flex-col">
        <img
          src={svgIcon}
          alt=""
          className="-mt-[20%] w-24 h-24 md:w-44 md:h-44"
        />
        <span className="mt-5 text-[#3D3D3D] font-bold text-sm md:text-2xl">
          {title}
        </span>
        <span className="text-xs md:text-md text-[#3D3D3D]">{designation}</span>
        <p className="text-xs md:text-base text-center px">{description}</p>
      </div>
    </animated.div>
  );
};

export default Card;
