import cloudInfrastructure from "../../assets/images/services/cloud/infrastructure.png";
import cloudInfrastructureMweb from "../../assets/images/services/cloud/infrastructure-mweb.png";
import cloudMigration from "../../assets/images/services/cloud/migration.png";
import cloudMigrationmWeb from "../../assets/images/services/cloud/migration-mweb.png";
import cloudManagement from "../../assets/images/services/cloud/management.png";
import cloudManagementmWeb from "../../assets/images/services/cloud/management-mweb.png";
import disasterRecovery from "../../assets/images/services/cloud/disaster-recovery.png";
import disasterRecoverymWeb from "../../assets/images/services/cloud/disaster-recovery-mweb.png";
import cloudSecurity from "../../assets/images/services/cloud/cloud-security.png";
import cloudSecuritymWeb from "../../assets/images/services/cloud/cloud-security-mweb.png";
import cloudConsulting from "../../assets/images/services/cloud/cloud-consulting.png";
import cloudConsultingmWeb from "../../assets/images/services/cloud/cloud-consulting-mweb.png";
import devops from "../../assets/images/services/cloud/devops.png";
import devopsmWeb from "../../assets/images/services/cloud/devops-mweb.png";

export const cloudServices = [
  {
    id: 1,
    title: "Cloud Infrastructure",
    logo: cloudInfrastructure,
    mobileLogo: cloudInfrastructureMweb,
    description:
      "Our cloud infrastructure provides a reliable and scalable platform for your business needs. We offer a range of cloud services, including public, private, and hybrid cloud solutions, to meet your specific requirements.",
    to: "/services/cloud-service/detailed-cloud-services#cloud-infrastructure",
    uuid: "cloud-infrastructure",
  },
  {
    id: 2,
    title: "Cloud Migration",
    logo: cloudMigration,
    mobileLogo: cloudMigrationmWeb,
    description:
      "Our cloud migration services enable you to move your existing IT infrastructure to the cloud seamlessly and efficiently.\n We can assist you in assessing your current environment, designing the appropriate cloud architecture, and executing the migration process.",
    to: "/services/cloud-service/detailed-cloud-services#cloud-migration",
    uuid: "cloud-migration",
  },
  {
    id: 3,
    title: "Cloud Management",
    logo: cloudManagement,
    mobileLogo: cloudManagementmWeb,
    description:
      "Our disaster recovery solutions ensure that your business can recover quickly from unexpected disruptions, such as natural disasters, cyber-attacks, or hardware failures.\n We can help you design and implement a disaster recovery plan that meets your recovery time and recovery point objectives.",
    to: "/services/cloud-service/detailed-cloud-services#cloud-management",
    uuid: "cloud-management",
  },
  {
    id: 4,
    title: "Disaster Recovery",
    logo: disasterRecovery,
    mobileLogo: disasterRecoverymWeb,
    description:
      "Our disaster recovery solutions ensure that your business can recover quickly from unexpected disruptions, such as natural disasters, cyber-attacks, or hardware failures.\nWe can help you design and implement a disaster recovery plan that meets your recovery time and recovery point objectives.",
    to: "/services/cloud-service/detailed-cloud-services#disaster-recovery",
    uuid: "disaster-recovery",
  },
  {
    id: 5,
    title: "Cloud Security",
    logo: cloudSecurity,
    mobileLogo: cloudSecuritymWeb,
    description:
      "Our cloud security services help protect your cloud environment from cyber threats and attacks. \nWe implement the latest security technologies and best practices, including firewalls, intrusion detection and prevention, and data encryption, to keep your data and applications secure.",
    to: "/services/cloud-service/detailed-cloud-services#cloud-security",
    uuid: "cloud-security",
  },
  {
    id: 6,
    title: "Cloud Consulting",
    logo: cloudConsulting,
    mobileLogo: cloudConsultingmWeb,
    description:
      "Our cloud consulting services provide expert advice and guidance on cloud strategy, architecture, and implementation.\n We can assist you in selecting the right cloud services for your business needs, and help you optimize your cloud environment for performance, cost efficiency, and security.",
    to: "/services/cloud-service/detailed-cloud-services#cloud-consulting",
    uuid: "cloud-consulting",
  },
  {
    id: 7,
    title: "Devops",
    logo: devops,
    mobileLogo: devopsmWeb,
    description:
      "Blue Orca Solutions provides comprehensive DevOps services to streamline software development and deployment in the cloud. We can help you design and implement Continuous Integration/Continuous Deployment (CI/CD) pipelines to automate the build, test, and deployment process, reducing the time and effort required to deliver high-quality software. Our experienced DevOps team can also help you optimize your development workflows, improve collaboration between development and operations teams, and implement best practices for agile software development. With our DevOps services, you can accelerate your software delivery, reduce errors and downtime, and improve overall efficiency and quality of your software development lifecycle.",
    to: "/services/cloud-service/detailed-cloud-services#devops",
    uuid: "devops",
  },
];
