import customAppDev from "../../assets/images/services/application-moderanization/custom-app-dev.png";
import customAppDevmWeb from "../../assets/images/services/application-moderanization/custom-app-dev-mweb.png";
import mobileAppDev from "../../assets/images/services/application-moderanization/mobile-app-dev.png";
import mobileAppDevmWeb from "../../assets/images/services/application-moderanization/mobile-app-dev-mweb.png";
import legacyAppModernization from "../../assets/images/services/application-moderanization/legacy-app-modernization.png";
import legacyAppModernizationmWeb from "../../assets/images/services/application-moderanization/legacy-app-modernization-mweb.png";
import cloudAppDev from "../../assets/images/services/application-moderanization/cloud-app-dev.png";
import cloudAppDevmWeb from "../../assets/images/services/application-moderanization/cloud-app-dev-mweb.png";
import devops from "../../assets/images/services/application-moderanization/devops.png";
import devopsmWeb from "../../assets/images/services/application-moderanization/devops-mweb.png";
import appMaintenance from "../../assets/images/services/application-moderanization/app-maintenance.png";
import appMaintenancemWeb from "../../assets/images/services/application-moderanization/app-maintenance-mweb.png";

export const applicationModeranizationServices = [
  {
    id: 1,
    title: "Custom Application Development",
    logo: customAppDev,
    mobileLogo: customAppDevmWeb,
    description: `Our Application Development and Modernization services provide custom application development to meet your specific business needs.
<br />We use modern development frameworks and methodologies to design, develop, and deploy custom applications that are scalable, reliable, and easy to maintain.`,
    to: "/services/application-modernization/detailed-application-modernization#custom-app",
    uuid: "custom-app",
  },
  {
    id: 2,
    title: "Mobile Application Development",
    logo: mobileAppDev,
    mobileLogo: mobileAppDevmWeb,
    description: `Our Application Development and Modernization services include mobile application development, enabling you to engage with your customers and employees on-the-go.
    <br />We use cross-platform development tools and frameworks to build native and hybrid mobile applications that are user-friendly, fast, and secure.`,
    to: "/services/application-modernization/detailed-application-modernization#mobile-dev",
    uuid: "mobile-dev",
  },
  {
    id: 3,
    title: "Legacy Application Modernization",
    logo: legacyAppModernization,
    mobileLogo: legacyAppModernizationmWeb,
    description: `Our Application Development and Modernization services include legacy application modernization, enabling you to modernize your existing applications to meet the demands of modern business.
    <br />We use modernization frameworks and methodologies to modernize your legacy applications, improving performance, scalability, and security.`,
    to: "/services/application-modernization/detailed-application-modernization#legacy-application-modernization",
    uuid: "legacy-application-modernization",
  },
  {
    id: 4,
    title: "Cloud-Native Application Development",
    logo: cloudAppDev,
    mobileLogo: cloudAppDevmWeb,
    description: `Our Application Development and Modernization services provide cloud-native application development, enabling you to leverage the benefits of cloud computing.
    <br />We use cloud-native development tools and frameworks to build applications that are designed for the cloud, enabling you to achieve scalability, flexibility, and cost efficiency.`,
    to: "/services/application-modernization/detailed-application-modernization#native-app-dev",
    uuid: "native-app-dev",
  },
  {
    id: 5,
    title: "DevOps and CI/CD",
    logo: devops,
    mobileLogo: devopsmWeb,
    description: `Our Application Development and Modernization services include DevOps and CI/CD, enabling you to streamline your application development and deployment processes.
    <br />We use DevOps and CI/CD practices to automate your application development and deployment workflows, ensuring that your applications are delivered quickly and reliably.`,
    to: "/services/application-modernization/detailed-application-modernization#devops-ci",
    uuid: "devops-ci",
  },
  {
    id: 6,
    title: "Application Maintenance and Support",
    logo: appMaintenance,
    mobileLogo: appMaintenancemWeb,
    description: `Our Application Development and Modernization services include application maintenance and support, enabling you to keep your applications up-to-date and secure.
    <br />We provide ongoing application maintenance and support services, including bug fixing, performance optimization, and security updates, ensuring that your applications are always running smoothly.`,
    to: "/services/application-modernization/detailed-application-modernization#application-maintenance",
    uuid: "application-maintenance",
  },
];
