import React, { Fragment } from "react";
import newLogo from "../../assets/images/new-logo.svg";
import blueOrcaLogoMobile from "../../assets/images/mobile-logo.svg";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <Fragment>
      <div className="hidden lg:block">
        <header className="flex justify-between items-center lg: px-12 xl:px-24">
          <Link to="/">
            <img
              src={newLogo}
              alt="Company Logo"
              className="py-8 cursor-pointer w-32 xl:w-64"
            />
          </Link>
          {renderNavBarItems}
          <div>
            <HashLink to={"/#get-in-touch"}>
              <button
                style={{
                  background:
                    "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
                }}
                className="w-24 xl:w-40 h-20 rounded-full cursor-pointer text-white font-bold text-lg relative"
              >
                Get In Touch
              </button>
            </HashLink>
          </div>
        </header>
      </div>
      <div className="lg:hidden py-4 px-4 flex items-center justify-between">
        <div>
          {/* // <!-- drawer init and show --> */}
          <button
            type="button"
            data-drawer-target="drawer-navigation"
            data-drawer-show="drawer-navigation"
            aria-controls="drawer-navigation"
          >
            <i className="fa fa-bars" />
          </button>

          <RenderDrawer />
        </div>
        <Link to="">
          <img
            src={blueOrcaLogoMobile}
            alt="Company Logo"
            className="cursor-pointer"
          />
        </Link>

        <HashLink to={"/#get-in-touch"}>
          <button
            style={{
              background: "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
            }}
            className="w-24 h-8 rounded-full cursor-pointer text-white font-bold text-xs relative min-w-max p-2"
          >
            Get In Touch
          </button>
        </HashLink>
      </div>
    </Fragment>
  );
};

const renderNavBarItems = (
  <ul className="flex space-x-4 text-sm xl:text-base">
    {[
      {
        label: "Home",
        to: "/",
      },
      {
        label: "Services",
        to: "/#services",
      },
      {
        label: "Case Studies",
        to: "/case-studies",
      },
      {
        label: "About Us",
        to: "/contact-us",
      },
      {
        label: "Blog",
        to: "/",
      },
      {
        label: "Contact Us",
        to: "/contact-us#contact-us",
      },
    ].map((navItem, key) => (
      <HashLink to={navItem.to}>
        <li
          className={`hover:text-[#FFFF] p-2 rounded-full hov btn-hover-${key} cursor-pointer`}
        >
          {navItem.label}
        </li>
      </HashLink>
    ))}
  </ul>
);

const RenderDrawer = () => {
  // console.log(useLocation());
  const { pathname } = useLocation();
  return (
    <div>
      {/* // <!-- drawer component --> */}
      <div
        id="drawer-navigation"
        className="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-64 dark:bg-gray-800"
        tabindex="-1"
        aria-labelledby="drawer-navigation-label"
      >
        <h5
          id="drawer-navigation-label"
          className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Menu
        </h5>
        <button
          type="button"
          data-drawer-hide="drawer-navigation"
          aria-controls="drawer-navigation"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to=""
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white ${
                  pathname === "/" && "bg-gray-100 dark:bg-gray-700"
                }`}
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
              >
                {/* <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <span className="ml-3">Home</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group dark:text-white"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                {/* <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clip-rule="evenodd"
                  ></path>
                </svg> */}
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Services
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul id="dropdown-example" className="hidden py-2 space-y-2">
                <li>
                  <Link
                    to={"services/managed-i-t-services"}
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/managed-i-t-services" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                  >
                    Managed IT Services
                  </Link>
                </li>
                <li>
                  <Link
                    to={"services/cloud"}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/cloud" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                  >
                    Cloud Services
                  </Link>
                </li>
                <li>
                  <Link
                    to={"services/cyber-security"}
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/cyber-security" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                  >
                    Cyber Security
                  </Link>
                </li>
                <li>
                  <Link
                    to={"services/devops"}
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/devops" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                  >
                    DevOps Services
                  </Link>
                </li>
                <li>
                  <Link
                    to={"services/application-modernization"}
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/application-modernization" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                  >
                    Application Modernization
                  </Link>
                </li>
                <li>
                  <Link
                    to={"services/data-science"}
                    className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white ${
                      pathname === "/services/data-science" &&
                      "bg-gray-100 dark:bg-gray-700"
                    }`}
                    data-drawer-hide="drawer-navigation"
                    aria-controls="drawer-navigation"
                  >
                    Data Science
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/case-studies"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white ${
                  pathname === "/services/case-studies" &&
                  "bg-gray-100 dark:bg-gray-700"
                }`}
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
              >
                {/* <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <span className="ml-3">Case Studies</span>
              </Link>
            </li>

            <li>
              <HashLink
                to="/contact-us"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
              >
                {/* <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <span className="ml-3">About Us</span>
              </HashLink>
            </li>

            <li>
              <HashLink
                to="/"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
              >
                {/* <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <span className="ml-3">Blog</span>
              </HashLink>
            </li>

            <li>
              <HashLink
                to="/contact-us#contact-us"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white ${
                  pathname === "/contact-us" && "bg-gray-100 dark:bg-gray-700"
                }`}
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
              >
                {/* <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <span className="ml-3">Contact Us</span>
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Header;
