import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Service = ({ title, description, logo, url }) => {
  return (
    <Fragment>
      <div
        className="w-[10.5rem] md:w-[22rem] h-20 md:h-72 rounded-md px-4 relative hover:scale-95"
        style={{
          boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
          borderBottom: "10px solid rgb(80 125 255)",
          transition: "transform .6s",
        }}
      >
        <div className="flex items-center justify-center relative -top-[1.7rem] md:-top-[4.5rem]">
          <div
            className="w-12 md:w-[6.3rem] h-12 md:h-[6.3rem] rounded-full flex items-center justify-center"
            style={{
              background: "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
            }}
          >
            <img src={logo} className="w-6 md:w-14 h-6 md:h-14" />
          </div>
        </div>
        <div className="-mt-6 md:-mt-12">
          <div className="text-xs md:text-xl md:min-w-max madeTommyDark">
            {title}
          </div>
          <div className="text-xs hidden md:block md:text-lg md:leading-relaxed font-normal text-[#6E6E6E] mt-3 h-32">
            {description}
          </div>
        </div>
        <Link to={url}>
          <div className="text-[#074DF8] font-bold text-[8px] md:text-base -mt-2 md:mt-4 cursor-pointer">
            Learn more
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default Service;
