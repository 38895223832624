import React from "react";
import appModernizationTitle from "../../assets/images/services/application-moderanization/application-moderization-title.png";

import { applicationModeranizationServices } from "../../utils/services/applicationModernization";
import ChildService from "../../components/ChildService/ChildService";
import CaseStudySection from "../../components/CaseStudySection/CaseStudySection";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CircularImageSecion from "../../components/CircularImageSection/CircularImageSecion";

const ApplicationModernization = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={appModernizationTitle}
          alt="application modernization"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute w-64 md:w-auto centerAlign top-[50%] text-xl md:text-4xl">
          Application Development & Modernization
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-16 pb-8 flex justify-center flex-wrap gap-6 2xl:gap-12 max-w-6xl mx-auto md:px-16">
        {applicationModeranizationServices.map((application) => (
          <ChildService
            title={application.title}
            logo={application.logo}
            mobileLogo={application.mobileLogo}
            url={application.to}
          />
        ))}
      </div>
      <CaseStudySection />
      <CircularImageSecion />
      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>
      <GetInTouch />
    </div>
  );
};

export default ApplicationModernization;
