import React from "react";
import appModernizationTitle from "../../assets/images/services/application-moderanization/application-moderization-title.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { applicationModeranizationServices } from "../../utils/services/applicationModernization";
import DetailChildService from "../../components/DetailChildService/DetailChildService";

const ApplicationModernization = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={appModernizationTitle}
          alt="application modernization"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute w-64 md:w-auto centerAlign top-[50%] text-xl md:text-4xl">
          Application Development & Modernization
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-16 pb-8 flex justify-center flex-wrap gap-6 2xl:gap-12 max-w-6xl mx-auto md:px-16">
        {applicationModeranizationServices.map((application) => (
          <DetailChildService
            id={application.id}
            logo={application.mobileLogo}
            title={application.title}
            description={application.description}
            uuid={application.uuid}
          />
        ))}

        {/* {cyberSecurityServices.map((itService, key) => (
          <DetailChildService
            id={itService.id}
            logo={itService.mobileLogo}
            title={itService.title}
            description={itService.description}
          />
        ))} */}
      </div>
    </div>
  );
};

export default ApplicationModernization;
