import vulnerabilityPenetration from "../../assets/images/services/cyber-security/vulnerability-penetration.png";
import vulnerabilityPenetrationmWeb from "../../assets/images/services/cyber-security/vulnerability-penetration-mweb.png";
import networkSecurity from "../../assets/images/services/cyber-security/network-security.png";
import networkSecuritymWeb from "../../assets/images/services/cyber-security/network-security-mweb.png";
import threatIntelligence from "../../assets/images/services/cyber-security/threat-intelligence.png";
import threatIntelligencemWeb from "../../assets/images/services/cyber-security/threat-intelligence-mweb.png";
import SIEM from "../../assets/images/services/cyber-security/SIEM.png";
import SIEMmWeb from "../../assets/images/services/cyber-security/SIEM-mweb.png";
import cloudSecurity from "../../assets/images/services/cyber-security/cloud-security.png";
import cloudSecuritymWeb from "../../assets/images/services/cyber-security/cloud-security-mweb.png";
import endpointSecurity from "../../assets/images/services/cyber-security/endpoint-security.png";
import endpointSecuritymWeb from "../../assets/images/services/cyber-security/endpoint-security-mweb.png";
import complianceRisk from "../../assets/images/services/cyber-security/compliance-risk.png";
import complianceRiskmWeb from "../../assets/images/services/cyber-security/compliance-risk-mweb.png";
import vCISO from "../../assets/images/services/cyber-security/vciso-service.png";

export const cyberSecurityServices = [
  {
    id: 1,
    title: `Vulnerability Assessment & Penetration Testing`,
    logo: vulnerabilityPenetration,
    mobileLogo: vulnerabilityPenetrationmWeb,
    description: `Our Cyber Security services provide vulnerability assessment and penetration testing to identify and address security weaknesses in your IT infrastructure. We use industry-standard tools and techniques to simulate attacks and identify vulnerabilities that could be exploited by cyber criminals.<br>Penetration Testing (Including Black Box, White Box, and Grey Box Testing)<ul style="list-style-type: disc;margin-left:18px"><li>Internal/External Infrastructure Testing</li><li>Wireless Penetration Testing</li><li>Web Application Testing</li><li>Cloud Penetration Testing (Including AWS and Azure)</li></ul>`,
    to: "/services/cyber-security/detailed-cyber-security#vulnerability-assessment",
    uuid: "vulnerability-assessment",
  },
  {
    id: 2,

    title: "Network Security",
    logo: networkSecurity,
    mobileLogo: networkSecuritymWeb,
    description: `Our Cyber Security services include network security to protect your IT infrastructure from cyber-attacks.
<br>
<br>
    We use firewalls, intrusion detection and prevention systems, and other advanced security technologies to secure your network and prevent unauthorized access.
    <br>
    <ul style="list-style-type: disc;margin-left:18px;"><li>
    Integrating <span style="color:#0a71f9;">Risk and Security</span></li></ul>`,
    to: "/services/cyber-security/detailed-cyber-security#network-security",
    uuid: "network-security",
  },
  {
    id: 3,

    title: "Cyber Threat Intelligence",
    logo: threatIntelligence,
    mobileLogo: threatIntelligencemWeb,
    description: `Our Cyber Security services provide cyber threat intelligence to help you stay ahead of emerging threats and cyber-attacks.
<br>
<br>
    We monitor the latest cyber threats and trends, providing real-time intelligence and analysis to help you anticipate and respond to cyber threats.
    `,
    to: "/services/cyber-security/detailed-cyber-security#cyber-threat",
    uuid: "cyber-threat",
  },
  {
    id: 4,

    title: "Security Information and Event Management (SIEM)",
    logo: SIEM,
    mobileLogo: SIEMmWeb,
    description: `Our Cyber Security services include SIEM, enabling you to monitor and analyze security events across your IT infrastructure. We use SIEM tools to collect and correlate security events, providing real-time visibility into security incidents and helping you respond quickly and effectively.
    <ul style="list-style-type: disc;display: flex;font-size: 14px;color: black;gap:40px">
    <div><li>Reporting Standards</li>
    <li>Incident Reporting</li></div>
    <div><li>Compliance Reporting</li>
    <li>Threat Intelligence Reporting</li></div>
    <div><li>Security Metrics Reporting</li>
    <li>Executive Summary Reporting</li> </div>
    <div><li>Regulatory Reporting</li>
    <li>Internal Reporting and Communication</li> </div>
    </ul>
    `,
    to: "/services/cyber-security/detailed-cyber-security#SIEM",
    uuid: "SIEM",
  },
  {
    id: 5,

    title: "Cloud Security",
    logo: cloudSecurity,
    mobileLogo: cloudSecuritymWeb,
    description: `Our Cyber Security services provide cloud security to protect your cloud infrastructure from cyber-attacks.
<br>
    We use advanced security technologies and best practices to secure your cloud environment and protect your data and applications.
    <br>
    <br>
    <ul style="list-style-type: disc;font-size: 14px;color: black;display:flex;gap:40px;margin-left:20px;">
    <li>AWS (Amazon Web Services) and Azure</li>
    <li>Cloud Penetration Testing (Including AWS and Azure)</li>
    </ul>
    `,
    to: "/services/cyber-security/detailed-cyber-security#cloud-security",
    uuid: "cloud-security",
  },
  {
    id: 6,

    title: "Endpoint Security",
    logo: endpointSecurity,
    mobileLogo: endpointSecuritymWeb,
    description: `Our Cyber Security services include endpoint security to protect your endpoints, such as laptops, desktops, and mobile devices, from cyber threats.
<br>
<br>
    We use endpoint protection tools and techniques to prevent malware infections, detect and respond to security incidents, and enforce security policies.
    `,
    to: "/services/cyber-security/detailed-cyber-security#endpoint-security",
    uuid: "endpoint-security",
  },
  {
    id: 7,

    title: "Compliance & Risk Management",
    logo: complianceRisk,
    mobileLogo: complianceRiskmWeb,
    to: "/services/cyber-security/detailed-cyber-security#compliance-risk-management",
  },
  {
    id: 8,

    title: "vCISO",
    logo: vCISO,
    mobileLogo: vCISO,
    to: "/vciso",
  },
];
