import React, { Fragment } from "react";
import Slider from "react-slick";
import "./Carousel.css";
import slideOne from "../../assets/images/slide-one.png";
import slideTwo from "../../assets/images/slide-two.png";
import slideThree from "../../assets/images/slide-three.png";
import slideFour from "../../assets/images/slide-four.png";
import slideFive from "../../assets/images/slide-five.png";
import slideSix from "../../assets/images/slide-six.png";
const Carousel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    autoplay: true,
    arrows: false,
  };

  const carousel = [
    {
      img: slideOne,
      title: "Empowering Your Digital Transformation",
      description:
        "Transform your digital landscape and achieve your business objectives with our comprehensive IT solutions and expertise.",
    },
    {
      img: slideTwo,
      title: "Modernize Your IT Services",
      description:
        "Transform your IT infrastructure and streamline your operations with our modernization services",
    },
    {
      img: slideThree,
      title: "Securing Your Digital Future",
      description:
        "Secure your digital assets and drive business growth with our innovative IT solutions and cybersecurity services.",
    },
    {
      img: slideFour,
      title: "vCISO",
      description:
        "Unlocking Your Vision with VCISO: Innovate, Secure, Succeed.",
    },
    {
      img: slideFive,
      title: "Power Bi",
      description: "PowerBI: Empowering Insights, Transforming Data.",
    },
    {
      img: slideSix,
      title: "Microsoft 365 (M365) and Azure Security Enhancement",
      description:
        "Elevate Your Cloud Security to the Next Level with Blue Orca Solutions, Your Trusted Microsoft Partner",
    },
  ];
  return (
    <div className="">
      <Slider {...settings}>
        {carousel.map((c) => (
          <div className="relative text-center text-white">
            <img src={c.img} alt={c.title} className="h-[102px] md:h-auto" />
            <div className="font-extrabold madeTommyDark absolute centerAlign md:w-full top-1/2 md:top-1/3 lg:top-1/4 text-base md:text-2xl xl:text-4xl">
              {c.title}
            </div>
            <span className="text-xs md:text-xl xl:text-2xl absolute centerAlign hidden lg:block top-[60%] md:top-[55%] w-72 md:w-[80%] lg:w-auto xl:w-[50rem]">
              {c.description}
            </span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
