import itSupport from "../../assets/images/services/it/it-support.png";
import itSupportmWeb from "../../assets/images/services/it/it-support-mweb.png";
import serverManagement from "../../assets/images/services/it/server-management.png";
import serverManagementmWeb from "../../assets/images/services/it/server-management-mweb.png";
import networkManagement from "../../assets/images/services/it/netwrok-management.png";
import networkManagementmWeb from "../../assets/images/services/it/netwrok-management-mweb.png";
import applicationManagement from "../../assets/images/services/it/application-management.png";
import applicationManagementmWeb from "../../assets/images/services/it/application-management-mweb.png";
import backupRecovery from "../../assets/images/services/it/backup-recovery.png";
import backupRecoverymWeb from "../../assets/images/services/it/backup-recovery-mweb.png";
import security from "../../assets/images/services/it/security.png";
import securitymWeb from "../../assets/images/services/it/security-mweb.png";
import cloudManagement from "../../assets/images/services/it/cloud-management.png";
import cloudManagementmWeb from "../../assets/images/services/it/cloud-management-mweb.png";

export const ItServices = [
  {
    id: 1,
    title: "IT Support",
    logo: itSupport,
    mobileLogo: itSupportmWeb,
    description:
      "Our Managed Services provide comprehensive IT support for your business needs. Our team of certified professionals can assist with everything from day-to-day maintenance to complex technical issues, ensuring that your business is running smoothly and efficiently.",
    to: "/services/managed-i-t-services/it-helpdesk-support#it-support",
    uuid: "it-support",
  },
  {
    id: 2,
    title: "Network Management",
    logo: networkManagement,
    mobileLogo: networkManagementmWeb,
    description:
      "Our Managed Services include network management, ensuring that your network is properly configured, monitored, and optimized.\nWe can help you design and implement a network infrastructure that meets your specific requirements and provide ongoing support and maintenance to ensure its continued performance.",
    to: "/services/managed-i-t-services/it-helpdesk-support#network-management",
    uuid: "network-management",
  },
  {
    id: 3,
    title: "Server Management",
    logo: serverManagement,
    mobileLogo: serverManagementmWeb,
    description:
      "Our Managed Services provide server management to ensure that your servers are properly configured, monitored, and maintained.\nWe can assist you in selecting the right server hardware and software and provide ongoing support and maintenance to ensure its reliability and performance.",
    to: "/services/managed-i-t-services/it-helpdesk-support#server-management",
    uuid: "server-management",
  },
  {
    id: 4,
    title: "Application Management",
    logo: applicationManagement,
    mobileLogo: applicationManagementmWeb,
    description:
      "Our Managed Services provide application management to ensure that your business-critical applications are properly configured, monitored, and optimized.\nWe can help you select and deploy the right applications for your business needs and provide ongoing support and maintenance to ensure their continued performance and availability.",
    to: "/services/managed-i-t-services/it-helpdesk-support#application-management",
    uuid: "application-management",
  },
  {
    id: 5,
    title: "Backup & Recovery",
    logo: backupRecovery,
    mobileLogo: backupRecoverymWeb,
    description:
      "Our Managed Services include data backup and recovery, ensuring that your critical data is properly backed up and recoverable in the event of data loss or disaster.\nWe can help you design and implement a backup and recovery plan that meets your recovery time and recovery point objectives and provide ongoing support and maintenance to ensure its effectiveness.",
    to: "/services/managed-i-t-services/it-helpdesk-support#backup-recovery",
    uuid: "backup-recovery",
  },
  {
    id: 6,
    title: "Security",
    logo: security,
    mobileLogo: securitymWeb,
    description:
      "Our Managed Services provide security services to protect your business from cyber threats and attacks.\nWe implement the latest security technologies and best practices, including firewalls, intrusion detection and prevention, and data encryption, to keep your data and applications secure.",
    to: "/services/managed-i-t-services/it-helpdesk-support#security",
    uuid: "security",
  },
  {
    id: 7,
    title: "Cloud Management",
    logo: cloudManagement,
    mobileLogo: cloudManagementmWeb,
    description:
      "Our Managed Services provide cloud management services, ensuring that your cloud environment is properly configured, optimized, and monitored.\nWe can assist you in selecting and deploying the right cloud services for your business needs and provide ongoing support and maintenance to ensure its performance and security.",
    to: "/services/managed-i-t-services/it-helpdesk-support#cloud-management",
    uuid: "cloud-management",
  },
];
