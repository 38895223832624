import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import itService from "../../assets/images/services/it/it-service.png";
import { ItServices } from "../../utils/services/it";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Technologies from "../../components/Technologies/Technologies";
import DetailChildService from "../../components/DetailChildService/DetailChildService";
import GetInTouch from "../../components/GetInTouch/GetInTouch";

const IT = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={itService}
          alt="it service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Managed IT Services
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-8 xl:pt-16 pb-8 flex justify-center flex-wrap gap-10 max-w-6xl mx-auto">
        {ItServices.map((itService, key) => (
          <DetailChildService
            id={itService.id}
            logo={itService.mobileLogo}
            title={itService.title}
            description={itService.description}
            uuid={itService.uuid}
          />
        ))}
      </div>
      <Technologies removeHeader={1} />
      <DynamicRibbon
        bgImg={expertsCallRibbon}
        description={"Book a call with our experts"}
      />
      <div className="mt-12 md:mt-24">
        <GetInTouch />
      </div>
    </div>
  );
};

export default IT;
