import React, { Fragment, useState, useEffect } from "react";
import Carousel from "../components/Carousel/Carousel";
import aboutUs from "../assets/images/about-us.svg";
import vciso from "../assets/images/vciso.png";
import powerBI from "../assets/images/power-bi.png";
import Services from "../components/Services/Services";
import Technologies from "../components/Technologies/Technologies";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import bgImg from "../assets/images/bg-color.jpeg";
// import projectsDoneRibbon from "../assets/images/projects-done-ribbon.png";
import Testimonials from "../components/Testimonials/Testimonials";
import expertsCallRibbon from "../assets/images/experts-call-ribbon.png";
import projectsDoneRibbon from "../assets/images/projects-done-ribbon.png";
import projectInMind from "../assets/images/project-in-mind-ribbon.png";
import DynamicRibbon from "../components/DynamicRibbon/DynamicRibbon";
import CustomSection from "../components/CustomSection/CustomSection";
import AnimatedRibbon from "../components/AnimatedRibbon/AnimatedRibbon";

const Home = () => {
  const [progressValues, setProgressValues] = useState({
    projects: 0,
    successRate: 0,
    clients: 0,
  });

  useEffect(() => {
    const duration = 5000; // Animation duration in milliseconds
    const startValue = {
      projects: 0,
      successRate: 0,
      clients: 0,
    };
    const endValue = {
      projects: 150,
      successRate: 100,
      clients: 50,
    };

    let startTimestamp = null;

    const animateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      let animatedValues = {};

      // loop over the object
      Object.keys(endValue).forEach(function (key, index) {
        animatedValues = {
          ...animatedValues,
          [key]: Math.floor(
            progress * (endValue[key] - startValue[key]) + startValue[key]
          ),
        };
      });

      setProgressValues(animatedValues);

      if (progress < 1) {
        window.requestAnimationFrame(animateValue);
      }
    };

    window.requestAnimationFrame(animateValue);
  }, []);

  return (
    <Fragment>
      <Carousel />
      <Services />
      <DynamicRibbon
        bgImg={expertsCallRibbon}
        description={"Book a call with our experts"}
      />
      <CustomSection
        image={vciso}
        heading={"vCISO"}
        subHeading={"Strengthen Defenses, Protect Assets"}
        description={
          "Empower your cybersecurity strategy with a virtual Chief Information Security Officer (vCISO). Elevate your security today."
        }
        to={"vciso"}
      />

      <AnimatedRibbon
        bgImg={projectsDoneRibbon}
        title={"Projects we have done"}
        attributes={[
          {
            title: "Projects",
            value: progressValues.projects,
          },
          {
            title: "Success Rate",
            value: progressValues.successRate,
          },
          {
            title: "Clients",
            value: progressValues.clients,
          },
        ]}
      />

      <CustomSection
        image={powerBI}
        heading={"Power BI"}
        subHeading={"Center of Excellence (COE)"}
        description={
          "Unlock the true potential of your data with our comprehensive Power BI COE services. Our team of Power BI experts is dedicated to helping businesses like yours harness the power of data analytics to drive informed decision-making and achieve your strategic goals."
        }
        reverse={1}
        to={'power-bi'}
      />
      <DynamicRibbon
        bgImg={projectInMind}
        description={"Have a project in mind?"}
      />
      <Technologies />
      <GetInTouch />
      <Testimonials />
    </Fragment>
  );
};

export default Home;
