import React, { Fragment } from "react";
import blueOrcaLogo from "../../assets/images/blue-orca-footer-logo.svg";
import blueOrcaLogoMobile from "../../assets/images/footer-logo-mweb.svg";
import facebookIcon from "../../assets/images/facebook-icon.svg";
import twitter from "../../assets/images/twitter.svg";
import linkedIn from "../../assets/images/linkedin.svg";
import microsoftPartner from "../../assets/images/microsoft-partner.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const socialIcons = [facebookIcon, twitter, linkedIn];

  const bottomToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="md:mt-16"
      style={{
        background: "linear-gradient(92.64deg, #000000 6.34%, #0745F8 115.36%)",
      }}
    >
      <div className="h-96 xl:h-[560px] text-white lg:px-24 mx-auto py-16 justify-between hidden lg:flex">
        <div className="flex flex-col justify-center">
          <img
            src={blueOrcaLogo}
            alt="Scroll to Top"
            className="w-44 h-56"
            onClick={bottomToTop}
          />
          <div className="logos flex gap-4 mt-8">
            {socialIcons.map((socialIcon) => (
              <div className="w-12 h-12 bg-white rounded-lg flex justify-center items-center cursor-pointer opacity-70">
                <img src={socialIcon} alt={`${socialIcon}`} />{" "}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <div className="flex gap-16 xl:gap-32">
            <div className="flex flex-col h-full min-w-max">
              <div className="text-3xl"> Our Services</div>
              <div className="leading-relaxed flex gap-y-5 flex-col mt-5 space-y-1">
                <Link to="services/managed-i-t-services">
                  <div className="text-base font-thin">IT Managed Services</div>
                </Link>
                <Link to="services/cyber-security">
                  <div className="text-base">Cyber Security Services</div>
                </Link>
                <Link to="services/cloud-service">
                  <div className="text-base">Cloud and DevOps Services</div>
                </Link>
                <Link to="services/data-science">
                  <div className="text-base">Data Sciences and Analytics</div>
                </Link>
                <Link to="services/application-modernization">
                  <div className="text-base">Application Modernization</div>
                </Link>
                <Link to="">
                  <div className="text-base">Resource Augmentation</div>
                </Link>
              </div>
            </div>

            <div className="flex flex-col h-full min-w-max">
              <div className="text-3xl">Company</div>
              <div className="leading-relaxed mt-5 flex gap-y-5 flex-col ">
                <Link to="">
                  <div className="text-base">What we do </div>
                </Link>
                <Link to="/contact-us">
                  <div className="text-base">Contact Us</div>
                </Link>
                <div className="text-base">Careers</div>
                <div className="text-base">Events</div>
              </div>
            </div>

            <div className="flex flex-col h-full min-w-max">
              <div className="text-3xl">Resources</div>
              <div className="leading-relaxed mt-5 flex gap-y-5 flex-col">
                <div className="text-base">News & Blogs </div>
                <div className="text-base">Webinar</div>
                <div className="text-base">Live Sessions</div>
              </div>
              <div className="md:hidden logos mt-12 flex gap-2">
                {socialIcons.map((socialIcon) => (
                  <div className="w-12 h-12 bg-white rounded-lg flex justify-center items-center cursor-pointer opacity-70">
                    <img src={socialIcon} alt={`${socialIcon}`} />{" "}
                  </div>
                ))}
              </div>
              <div className="mt-16">
                <img src={microsoftPartner} className="w-[220px] h-[79px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="md:mt-12 h-32 px-7 text-white py-6 justify-between flex items-center lg:hidden"
        style={{
          background:
            "linear-gradient(92.64deg, #000000 6.34%, #0745F8 115.36%)",
        }}
      >
        <div className="flex justify-center">
          <img
            src={blueOrcaLogoMobile}
            alt="Scroll to Top"
            onClick={bottomToTop}
            className="w-[43px] h-[62px]"
          />
        </div>
        <div className="flex justify-center flex-col text-xs md:text-sm madeTommyDark">
          <HashLink to="/#services">
            <div>Our Services</div>
          </HashLink>
          <HashLink to={"/"}>
            <div>Company</div>
          </HashLink>
          <HashLink to={"/"}>
            <div>Resources</div>
          </HashLink>
          <img src={microsoftPartner} className="w-[63px] h-[22px] mt-2" />
        </div>

        <div className="flex flex-col gap-2 justify-center">
          {socialIcons.map((socialIcon) => (
            <div className="bg-white w-6 h-6 rounded-lg cursor-pointer opacity-70">
              <img src={socialIcon} alt={`${socialIcon}`} />{" "}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
