import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import vCISO from "../assets/images/vcisco/vciso-powerbi-banner.png";
import title from "../assets/images/power-bi/title.png";
import service from "../assets/images/power-bi/services.png";
import CaseStudySection from "../components/CaseStudySection/CaseStudySection";
import attributes from "../assets/images/services/cyber-security/attributes.png";
import AnimatedRibbon from "../components/AnimatedRibbon/AnimatedRibbon";
import DynamicRibbon from "../components/DynamicRibbon/DynamicRibbon";
import expertsCallRibbon from "../assets/images/experts-call-ribbon.png";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import slideFive from "../assets/images/slide-five.png";
import slideSix from "../assets/images/slide-six.png";
import Slider from "react-slick";

const PowerBI = () => {
  const [progressValues, setProgressValues] = useState({
    testingProjects: 0,
    assessments: 0,
    remediation: 0,
    trainings: 0,
  });
  const services = [
    {
      title: "Governance Solutions",
      description:
        "Enhance report security, increase adoption, and scale your Power BI deployment. Our governance solutions...",
      clx: "h-52 w-[30%] px-3",
    },
    {
      title: "Custom Visuals",
      description:
        "Expand your reporting capabilities with our collection of 30+ certified custom visuals, available for free on...",
      clx: "h-52 w-[31.2%] px-3",
    },
    {
      title: "Migration Services",
      description:
        "Migrate your reports seamlessly from platforms like Tableau, SAP BusinessObjects, Qlik, and more. Our...",
      clx: "h-52 w-[31.2%] px-3",
    },
    {
      title: "Adoption Services",
      description:
        "Ensure a successful adoption of Power BI within your organization. Our certified engineers deliver Centers of...",
      clx: "h-52 w-[31.2%] px-3",
    },
  ];

  useEffect(() => {
    const duration = 5000; // Animation duration in milliseconds
    const startValue = {
      testingProjects: 0,
      assessments: 0,
      remediation: 0,
      trainings: 0,
    };
    const endValue = {
      testingProjects: 150,
      assessments: 150,
      remediation: 122,
      trainings: 50,
    };

    let startTimestamp = null;

    const animateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      let animatedValues = {};

      // loop over the object
      Object.keys(endValue).forEach(function (key, index) {
        animatedValues = {
          ...animatedValues,
          [key]: Math.floor(
            progress * (endValue[key] - startValue[key]) + startValue[key]
          ),
        };
      });

      setProgressValues(animatedValues);

      if (progress < 1) {
        window.requestAnimationFrame(animateValue);
      }
    };

    window.requestAnimationFrame(animateValue);
  }, []);

  const carousel = [
    {
      img: slideFive,
      title: "Power Bi",
      description: "PowerBI: Empowering Insights, Transforming Data.",
    },
    {
      img: slideSix,
      title: "Microsoft 365 (M365) and Azure Security Enhancement",
      description:
        "Elevate Your Cloud Security to the Next Level with Blue Orca Solutions, Your Trusted Microsoft Partner",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    autoplay: true,
    arrows: false,
  };
  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img src={vCISO} alt="vCISO" className="h-[140px] md:h-auto" />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Power BI Center of Excellence (COE)
        </div>
      </div>
      <Breadcrumb />
      <div className="">
        <div className="text-4xl madeTommyDark pt-7 max-w-6xl mx-auto">
          Welcome to{" "}
          <span className="gradientText">Power BI Center of Excellence</span>{" "}
          (COE)
        </div>
        <div className="flex pt-16 gap-12 max-w-6xl mx-auto">
          <div className="text-lg text-[#808080]">
            Unlock the true potential of your data with our comprehensive Power
            BI COE services. Our team of Power BI experts is dedicated to
            helping businesses like yours harness the power of data analytics to
            drive informed decision-making and achieve your strategic goals.
            With our data-driven approach, powerful visualizations, and ongoing
            support, we empower you to make sense of complex datasets, uncover
            hidden insights, and foster a data-driven culture throughout your
            organization, ensuring you stay ahead of the competition and achieve
            unprecedented success.
          </div>
          <img src={title} className="rounded-3xl" alt="s" />
        </div>

        <div className="text-4xl madeTommyDark pt-24 text-center">Services</div>
        <div className="pt-9 max-w-6xl mx-auto">
          <div className="flex gap-5">
            <div className="w-2/3 flex flex-wrap gap-5">
              <div
                style={{
                  background:
                    "var(--check, linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%))",
                  boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
                }}
                className="rounded-[40px] w-2/3 flex items-center justify-center h-52"
              >
                <img src={service} className="py-6 px-auto" />
              </div>
              {services?.map((service) => (
                <DynamicBox
                  title={service.title}
                  description={service.description}
                  clx={service.clx}
                />
              ))}
            </div>

            {/* for large box */}
            <div className="w-1/3">
              <DynamicBox
                title={"Optimization Services"}
                description={`Optimize the performance of your Power BI reports and reduce load time by an average of 50%. Our exclusive performance analysis tools, including the magical Optimize the performance of your Power BI reports and reduce load time by an average of 50%. Our exclusive performance analysis tools, including the magical "Analytics Genie," quickly identify areas for improvement.`}
                clx={"px-8 h-full"}
              />
            </div>
          </div>
        </div>

        <div className="pt-32">
          <AnimatedRibbon
            bgImg={attributes}
            title={"Attributes"}
            attributes={[
              {
                title: "Workshops",
                value: progressValues.testingProjects,
              },
              {
                title: "Assessments",
                value: progressValues.assessments,
              },
              {
                title: "Migrations",
                value: progressValues.remediation,
              },
              {
                title: "Trainings",
                value: progressValues.trainings,
              },
            ]}
          />
        </div>

        <CaseStudySection />
        <div className="mt-12 md:mt-24">
          <Slider {...settings}>
            {carousel.map((c) => (
              <div className="relative text-center text-white">
                <img
                  src={c.img}
                  alt={c.title}
                  className="h-[102px] md:h-auto"
                />
                <div className="font-extrabold madeTommyDark absolute centerAlign md:w-full top-1/2 md:top-1/3 lg:top-1/4 text-base md:text-2xl xl:text-4xl">
                  {c.title}
                </div>
                <span className="text-xs md:text-xl xl:text-2xl absolute centerAlign hidden lg:block top-[60%] md:top-[55%] w-72 md:w-[80%] lg:w-auto xl:w-[50rem]">
                  {c.description}
                </span>
              </div>
            ))}
          </Slider>
        </div>

        <div className="mt-12 md:mt-24">
          <DynamicRibbon
            bgImg={expertsCallRibbon}
            description={"Book a call with our experts"}
          />
        </div>

        <div className="mt-12 md:mt-24">
          <GetInTouch />
        </div>
      </div>
    </Fragment>
  );
};

export default PowerBI;

const DynamicBox = ({ title, description, clx }) => {
  return (
    <div
      style={{
        boxShadow: "14px 17px 22px 0px rgba(0, 133, 255, 0.26)",
      }}
      className={`rounded-[40px] py-6 ${clx}`}
    >
      <div className="text-xl font-bold">{title}</div>
      <div className="text-lg">{description}</div>
    </div>
  );
};
