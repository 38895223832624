import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const segments = useLocation().pathname.split("/").filter(Boolean);

  return (
    <div className="pt-2 xl:pt-8 md:flex justify-center flex-wrap max-w-6xl md:px-16">
      <div className="font-thin text-black text-xs md:text-2xl pl-12 md:pl-0">
        <Link to="/" className="text-[#08AAFB]">
          Home
        </Link>
        {segments.map((segment, index) => (
          <React.Fragment key={index}>
            {index < segments.length && " / "}
            <Link
              to={`/${
                segments.slice(0, index + 1).join("/") == "services"
                  ? "#services"
                  : segments.slice(0, index + 1).join("/")
              }`}
            >
              {capitalizeAndReplaceHyphens(segment)}
            </Link>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

function capitalizeAndReplaceHyphens(str) {
  return str.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

export default Breadcrumb;
