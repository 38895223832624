import React from "react";

const DetailChildService = ({ id, logo, title, description, uuid }) => {
  return (
    <div
      className={`max-w-6xl mx-auto flex ${
        id % 2 != 0 ? "flex-row" : "flex-row-reverse"
      } gap-7`}
      id={uuid}
    >
      <img src={logo} className="w-[146px] h-[250px] rounded-2xl" />
      <div
        className="rounded-3xl py-5 px-7"
        style={{
          boxShadow: "0px 3.002px 3.002px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <span className="madeTommyDark text-4xl">{title}</span>
        <div
          className="text-lg mt-9 text-[#808080]"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default DetailChildService;
