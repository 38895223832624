import React, { Fragment } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import dataScience from "../../assets/images/services/data-science/data-science.png";
import { dataScienceServices } from "../../utils/services/dataScience";
import DetailChildService from "../../components/DetailChildService/DetailChildService";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";

const DataScience = () => {
  return (
    <Fragment>
      <div className="relative text-center text-white bg-local">
        <img
          src={dataScience}
          alt="data service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Data Science
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-8 xl:pt-16 pb-8 flex justify-center flex-wrap gap-10 max-w-6xl mx-auto">
        {dataScienceServices
          .filter((service) => service.description)
          .map((dataScience, key) => (
            <DetailChildService
              id={dataScience.id}
              logo={dataScience.mobileLogo}
              title={dataScience.title}
              description={dataScience.description}
              uuid={dataScience.uuid}
            />
          ))}
      </div>

      <div className="mt-12 md:mt-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>
      <div className="mt-12 md:mt-24">
        <GetInTouch />
      </div>
    </Fragment>
  );
};

export default DataScience;
