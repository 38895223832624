import React from "react";

const AnimatedRibbon = ({ bgImg, title, attributes }) => {
  return (
    <div className="relative text-center text-white">
      <img
        src={bgImg}
        className="h-[150px] lg:h-[216px]"
        alt="Securing Your Digital Future"
      />
      <div className="font-extrabold absolute madeTommyDark centerAlign md:w-full top-1/4 text-sm md:text-2xl xl:text-4xl">
        {title}
      </div>
      <div className="font-extrabold absolute madeTommyDark flex top-2/3 centerAlign text-base md:text-2xl xl:text-4xl justify-between max-w-6xl mx-auto min-w-[80%] lg:min-w-[50%]">
        {attributes?.map((attribute) => {
          return (
            <div>
              <div className="text-base md:text-4xl">{attribute.value}+</div>
              <div className="text-xs md:text-xl">{attribute.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnimatedRibbon;
