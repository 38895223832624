import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import img from "../../assets/images/get-in-touch.png";
import "./GetInTouch.css";

const GetInTouch = () => {
  const form = useRef();
  const YOUR_PUBLIC_KEY = "K0ov3BEiLlDxihdbI";
  const serviceID = "default_service";
  const templateID = "contact_form";
  const [submitButtonText, setSubmitButtonText] = useState("Send");

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitButtonText("Sending ...");
    console.log(form);
    emailjs.sendForm(serviceID, templateID, form.current, YOUR_PUBLIC_KEY).then(
      (result) => {
        setSubmitButtonText("Sent");
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 3000);
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <div
      className="px-5 lg:px-0 lg:h-44"
      id="get-in-touch"
      style={{
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
      }}
    >
      <div className="rounded-md py-6 px-4 flex flex-col gap-4 md:gap-8 max-w-6xl mx-auto">
        <div className="text-white text-xl md:text-3xl font-extrabold text-center madeTommyDark">
          Get in touch with us
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col lg:flex-row gap-4 justify-between">
            <input
              type="text"
              placeholder="First Name"
              name="from_name"
              className="rounded-md 2xl:w-52 lg:h-14 p-2 lg:p-4"
              required
            ></input>
            <input
              type="text"
              placeholder="Last Name"
              name="last_name"
              className="rounded-md 2xl:w-52 lg:h-14 p-2 lg:p-4"
              required
            ></input>
            <input
              type="text"
              placeholder="Enter Email"
              name="sender_email"
              className="rounded-md 2xl:w-[37.5rem] lg:h-14 p-2 lg:p-4"
              required
            ></input>
            <input
              type="submit"
              disabled={submitButtonText === "Sent" ? true : false}
              value={submitButtonText}
              className={`rounded-md w-full lg:w-48 2xl:w-52 h-14 lg:h-14 text-white font-extrabold text-xl md:text-2xl cursor-pointer`}
              style={{
                background:
                  submitButtonText === "Sent"
                    ? "gray"
                    : "linear-gradient(180deg, #08AAFB 0%, #0745F8 107.5%)",
              }}
            ></input>
          </div>
        </form>
        <div className="flex items-center justify-center">
          <div id="snackbar">Message sent successfully!</div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
