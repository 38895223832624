import React from "react";
import ChildService from "../../components/ChildService/ChildService";
import cloudService from "../../assets/images/services/cloud/cloud-services.png";
import { cloudServices } from "../../utils/services/cloud";
import CaseStudySection from "../../components/CaseStudySection/CaseStudySection";
import DynamicRibbon from "../../components/DynamicRibbon/DynamicRibbon";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import expertsCallRibbon from "../../assets/images/experts-call-ribbon.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Cloud = () => {
  return (
    <div>
      <div className="relative text-center text-white bg-local">
        <img
          src={cloudService}
          alt="cloud service"
          className="h-[140px] md:h-[216px]"
        />
        <div className="font-extrabold absolute centerAlign top-[50%] text-xl md:text-4xl">
          Cloud Services
        </div>
      </div>
      <Breadcrumb />
      <div className="pt-2 md:pt-16 pb-8 flex justify-center flex-wrap gap-6 2xl:gap-12 max-w-6xl mx-auto">
        {cloudServices.map((cloudService) => (
          <ChildService
            title={cloudService.title}
            shortDescription={cloudService.shortDescription}
            shortDescriptionEnd={cloudService.shortDescriptionEnd}
            longDescription={cloudService.longDescription}
            logo={cloudService.logo}
            mobileLogo={cloudService.mobileLogo}
            url={cloudService.to}
          />
        ))}
      </div>

      <CaseStudySection />

      <div className="my-12 md:my-24">
        <DynamicRibbon
          bgImg={expertsCallRibbon}
          description={"Book a call with our experts"}
        />
      </div>

      <GetInTouch />
    </div>
  );
};

export default Cloud;
